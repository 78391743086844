import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchWorkspaces } from "../../services/workspaceService";
import routes from "../../configs/routes";
import WorkspaceNav from "./WorkspaceNav";
import Heading from "../../components/Heading/Heading";
import Visibility from "../../components/Workspace/Visibility";
import Alert from "../../components/Alert/Alert";
import AccountCard from "../../components/User/AccountCard";

const WorkspaceList = () => {
  const { t } = useTranslation();
  const { session, setSession, account, isWorkspaceOwner } = useAccount();
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const { data: workspaces, isPending, error } = useFetchWorkspaces();

  useEffect(() => {
    if (!isPending && workspaces?.length > 0 && session.workspace) {
      const workspace = workspaces.find((w) => w.uuid === session.workspace);
      setSelectedWorkspace(workspace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.workspaceUuid, workspaces, isPending]);

  const handleSelectWorkspace = (uuid) => {
    const workspace = workspaces.find((w) => w.uuid === uuid);

    // Prevent closing the modal if the selected workspace is already selected
    if (selectedWorkspace?.uuid === workspace.uuid) {
      return;
    }

    setSession((prev) => ({
      ...prev,
      workspace: workspace.uuid,
    }));

    setSelectedWorkspace(workspace);

    // @TODO: Fix force a page reload (for now )
    window.location.reload();
  };

  return (
    <>
      <Heading
        to={routes.auth.workspace.overview}
        back={t("Workspace")}
        title={t("Your workspaces")}
      />
      <WorkspaceNav
        isOwner={isWorkspaceOwner(selectedWorkspace?.owner_uuid) ?? false}
      />
      <section className="pt-4">
        {error && (
          <Alert variant="danger" title={t("Failed")}>
            {t("Something went wrong! Please try again later.")}
          </Alert>
        )}
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-list fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("List of workspaces")}</h3>
            </div>
            <p className="small mb-0">
              {t("Here you can see & switch between workspaces")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="d-flex justify-content-end">
              <Link
                to={routes.auth.workspace.create}
                className="btn btn-secondary"
              >
                {t("Create workspace")}
              </Link>
            </div>
          </div>
        </div>
        <table className="mt-4 table">
          <thead>
            <tr>
              <th scope="col">{t("Workspace name")}</th>
              <th scope="col">{t("Owner")}</th>
              <th scope="col">{t("Who can access?")}</th>
              <th scope="col text-end"></th>
            </tr>
          </thead>
          {!isPending && workspaces?.length > 0 && (
            <tbody>
              {workspaces?.map((workspace, index) => (
                <tr
                  key={`workspace-member-${index}_${workspace.uuid} ${
                    selectedWorkspace?.uuid === workspace.uuid
                      ? "cursor-default"
                      : "cursor-pointer hover-flex"
                  } `}
                >
                  <td className="align-middle">
                    <div className="fw-semibold mb-0">{workspace.name}</div>
                    <div className="text-muted small">1 member</div>
                  </td>
                  <td className="align-middle d-flex align-items-center">
                    <AccountCard
                      firstName={workspace.owner.first_name}
                      lastName={workspace.owner.last_name}
                      avatar={workspace.owner.avatar}
                      detail={workspace.owner.email}
                    />
                    {workspace.owner.uuid === account.uuid && (
                      <div className="ms-2">
                        <div className="badge bg-success bg-opacity-10 text-success ms-2">
                          {t("You")}
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    <Visibility visibility={workspace.visibility} />
                  </td>
                  <td className="align-middle text-end">
                    {selectedWorkspace?.uuid === workspace.uuid ? (
                      <div className="bg-success rounded py-1 px-2 bg-opacity-10 text-center text-success">
                        {t("Current")}
                      </div>
                    ) : (
                      <button
                        className="btn btn-light text-center w-100 py-1 px-2"
                        onClick={() => handleSelectWorkspace(workspace.uuid)}
                      >
                        <div className="d-flex gap-1 align-items-center justify-content-center">
                          <i className="ti ti-switch-horizontal fs-5"></i>
                          <span>{t("Switch")}</span>
                        </div>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </section>
    </>
  );
};

export default WorkspaceList;

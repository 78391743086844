import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CollectionContext } from "../../../contexts/CollectionContext";
import EditForm from "../Form/EditForm";
import Heading from "../../Heading/Heading";
import DeleteAction from "../Item/Delete/ButtonDelete";
import routes from "../../../configs/routes";
import CollectionNav from "../../Heading/CollectionNav";
import { formatDate } from "../../../utils/helpers";

const Edit = () => {
  const { t } = useTranslation();
  // @todo: Implement the isPending and error variables
  // eslint-disable-next-line no-unused-vars
  const { collection, isPending, error } = useContext(CollectionContext);
  const [collectionData, setCollectionData] = useState({});

  useEffect(() => {
    if (!collection) return;
    setCollectionData(collection);
  }, [collection]);

  return (
    <>
      <Heading
        back={t("Collections")}
        to={routes.auth.collections}
        title={t("Edit collection")}
      />
      <CollectionNav uuid={collectionData?.uuid} />

      <EditForm collectionData={collectionData} />

      {collectionData?.uuid && (
        <>
          <section className="border-top mt-4 pt-4">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-user-scan fs-4 text-primary" />
                  <h3 className="fs-5 mb-0">{t("Metadata")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This section contains metadata information about the collection."
                  )}
                </p>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Author")}</div>
                    <div className="mt-1">
                      <p className="mb-0">
                        {collectionData?.user?.first_name}{" "}
                        {collectionData?.user?.last_name}
                      </p>
                      <p className="mb-0 small">{collectionData?.user?.email}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Last updated")}</div>
                    <div className="mt-1">
                      {formatDate(collectionData?.updatedAt)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Created on")}</div>
                    <div className="mt-1">
                      {formatDate(collectionData?.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="border-top mt-4 pt-4">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-alert-triangle fs-4 text-danger" />
                  <h3 className="fs-5 mb-0">{t("Danger zone")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This action will delete all endpoints and requests associated with this collection."
                  )}
                </p>
              </div>
              <div className="col-lg-8 d-flex justify-content-end">
                <div>
                  <DeleteAction collectionUuid={collectionData.uuid} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Edit;

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const slugifyDomain = (text) => {
  return text
    .toString() // Convert to string
    .toLowerCase() // Convert to lowercase
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/[^\w-]+/g, "") // Remove all non-word characters except dashes
    .replace(/--+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-/, ""); // If starts with a dash, remove it
};

export const slugifyPath = (input) => {
  let slug = input
    .toString()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/\/\s+/g, "/") // Ensure no spaces after slashes
    .replace(/^-/, ""); // Remove leading and trailing dashes

  // Ensure the path starts with a slash
  if (!slug.startsWith("/")) {
    slug = "/" + slug;
  }
  return slug;
};

export const generateDomain = (length = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from({ length }, () =>
    chars.charAt(Math.floor(Math.random() * chars.length))
  ).join("");
};

export const apiDomain = (domain, path = "") => {
  // If development environment
  if (process.env.REACT_APP_ENV === "development") {
    return `http://${domain}.${process.env.REACT_APP_MOCK_URL}${path}`;
  }
  return `https://${domain}.castapi.cloud${path}`;
};

/**
 * Formats a date string or Date object with default formatting and optional overrides
 * @param {string|Date} date - The date to format
 * @param {Object} options - Optional Intl.DateTimeFormat options to override defaults
 * @returns {string} Formatted date string
 */
export const formatDate = (date, options = {}) => {
  if (!date) return;

  const d = date instanceof Date ? date : new Date(date);

  if (isNaN(d.getTime())) throw new Error("Invalid date provided");

  const defaultOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", {
    ...defaultOptions,
    ...options,
  }).format(d);
};

export const parseError = (error) => {
  if (!error) {
    return {
      message: "Something went wrong, Try again",
    };
  }
  // check if error is a stringfied object
  if (typeof error === "string") {
    try {
      error = JSON.parse(error);
    } catch (e) {
      return {
        message: error,
      };
    }
  }
  return error;
};

/**
 * Build a full name from first and last name
 * @param {string} firstName - The first name
 * @param {string} lastName - The last name
 * @returns {string} The full name
 * @deprecated Use getFullName instead
 */
export const fullName = (firstName, lastName) => {
  return getFullName(firstName, lastName);
};

/**
 * Build a full name from first and last name
 * @param {string} firstName - The first name
 * @param {string} lastName - The last name
 * @returns {string} The full name
 */
export const getFullName = (firstName, lastName) => {
  return `${firstName} ${lastName}`;
};

const _getFirstChar = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.trim().charAt(0).toUpperCase();
};

/**
 * Generate initials from first name and optional last name
 * @param {string} firstName - Required first name
 * @param {string} [lastName] - Optional last name
 * @returns {string} Uppercase initials
 * @throws {Error} If firstName is not provided or invalid
 */
export const getInitials = (firstName, lastName) => {
  const firstInitial = _getFirstChar(firstName);
  const lastInitial = _getFirstChar(lastName);

  return `${firstInitial}${lastInitial}`.toUpperCase();
};

/**
 * Ger an item from the local storage
 * @param {string} key - The key to store the item
 * @returns {any} The item to store
 */
export const getFromStorage = (key) => {
  const item = localStorage.getItem(key);
  if (!item) return null;
  return JSON.parse(item);
};

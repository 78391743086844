import { EndpointList, EndpointForm } from "../../pages/Endpoints";
import routes from "../../configs/routes";

export const EndpointRoutes = [
  {
    path: routes.auth.endpoint.list(":uuid"),
    Component: EndpointList,
  },
  {
    path: routes.auth.endpoint.create(":collectionUuid"),
    Component: EndpointForm,
  },
  {
    path: routes.auth.endpoint.edit(":uuid"),
    Component: EndpointForm,
  },
];

import OrganizationGeneral from "../../pages/Organization/OrganizationGeneral";
import OrganizationMembers from "../../pages/Organization/OrganizationMembers";
import InviteMembers from "../../pages/Organization/InviteMembers";
import routes from "../../configs/routes";

export const OrganizationRoutes = [
  {
    path: routes.auth.organization.overview,
    Component: OrganizationGeneral,
  },
  {
    path: routes.auth.organization.members,
    Component: OrganizationMembers,
  },
  {
    path: routes.auth.organization.invite,
    Component: InviteMembers,
  },
];

import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../../configs/routes";
import { Import, GenerateEndpoint, GenerateCrud } from "./StartOptions";

const NewEndpointOptions = ({ collection, endpoints }) => {
  const { t } = useTranslation();
  const [assistant, setAssitant] = useState(
    endpoints?.length > 0 ? "endpoint" : "crud"
  );

  return (
    <div className="row mt-5 text-center">
      <div className="col-lg-4 mb-2 d-flex align-items-stretch">
        <div className="card card__action p-5 position-relative w-100">
          <i className="ti bg-info bg-opacity-10 icon avatar m-auto p-2 ti-square-rounded-plus fs-3 text-secondary"></i>
          <h5 className="mt-3">{t("Create manually")}</h5>
          <p>{t("Build your endpoint from scratch")}</p>
          <Link
            to={routes.auth.endpoint.create(collection.uuid)}
            className="stretched-link disabled"
            disabled
          ></Link>
        </div>
      </div>
      <div className="col-lg-4 position-relative mb-2 d-flex align-items-stretch">
        <div className="card-wrapper w-100">
          <ul className="nav nav-segment position-absolute bottom-0 start-50 translate-middle-x mb-3 z-3 small">
            <li
              className="nav-item"
              role="button"
              onClick={() => setAssitant("endpoint")}
            >
              <span
                className={`nav-link px-3 py-1 ${
                  assistant === "endpoint"
                    ? "active cursor-default"
                    : "cursor-pointer"
                }`}
              >
                {t("Endpoint")}
              </span>
            </li>
            <li
              className="nav-item"
              role="button"
              onClick={() => setAssitant("crud")}
            >
              <span
                className={`nav-link px-3 py-1 ${
                  assistant === "crud"
                    ? "active cursor-default"
                    : "cursor-pointer"
                }`}
              >
                {t("CRUD")}
              </span>
            </li>
          </ul>
          {assistant === "endpoint" ? (
            <GenerateEndpoint uuid={collection.uuid} />
          ) : (
            <GenerateCrud collection={collection} />
          )}
        </div>
      </div>
      <div className="col-lg-4 mb-2 d-flex align-items-stretch">
        <Import collectionUuid={collection.uuid} />
      </div>
    </div>
  );
};

export default NewEndpointOptions;

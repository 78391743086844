const StatusBadge = ({ status, size }) => {
  const responseCodeToColor = (code) => {
    if (code >= 100 && code < 200) {
      return "pink";
    } else if (code >= 200 && code < 300) {
      return "success";
    } else if (code >= 300 && code < 400) {
      return "info";
    } else if (code >= 400 && code < 500) {
      return "warning";
    } else if (code >= 500) {
      return "danger";
    }
  };

  /**
   * Converts HTTP status codes to their standard text descriptions
   * @param {number} code - HTTP status code
   * @returns {string} Status code description or "Unknown Status Code" if not found
   */
  const responseCodeToText = (code) => {
    const statusCodes = {
      // 2xx Success
      200: "OK",
      201: "Created",
      204: "No Content",

      // 3xx Redirection
      301: "Moved Permanently",
      302: "Found",
      304: "Not Modified",

      // 4xx Client Errors
      400: "Bad Request",
      401: "Unauthorized",
      403: "Forbidden",
      404: "Not Found",
      409: "Conflict",
      429: "Too Many Requests",

      // 5xx Server Errors
      500: "Internal Server Error",
      502: "Bad Gateway",
      503: "Service Unavailable",
      504: "Gateway Timeout",
    };

    return statusCodes[code] || "";
  };

  return (
    <span
      className={`d-flex align-items-center gap-1 text-${responseCodeToColor(
        status
      )}`}
    >
      <i className={`ti ti-circle-dot ${size ? size : "fs-5"}`}></i>
      <span>{status}</span>
      <span>{responseCodeToText(status)}</span>
    </span>
  );
};

export default StatusBadge;

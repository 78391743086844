import "./Forms.scss";

export default function Textarea({
  name,
  label,
  rows,
  register,
  errors,
  ...rest
}) {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        id={name}
        {...(register ? register(name) : {})}
        className={`form-control ${errors?.[name] ? "is-invalid" : ""}`}
        rows={rows ?? 3}
        {...rest}
      />
      {errors?.[name] && (
        <div className="first-letter-cap position-absolute invalid-feedback ms-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
}

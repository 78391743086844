import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogin } from "../../services/authService";
import { useAuth } from "../../providers/AuthProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { parseError, getFromStorage } from "../../utils/helpers";
import Input from "../../components/Forms/Input";
import InputPassword from "../../components/Forms/InputPassword";
import Button from "../../components/Button/Button";
import routes from "../../configs/routes";
import logo from "../../assets/logos/logo.svg";
import Alert from "../../components/Alert/Alert";
import GitHubLogin from "../../components/Auth/GitHubLogin";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("It looks like your email is invalid")
    .required("Provide your email address"),
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters")
    .required("Your password is required here"),
  remember: Yup.bool(),
});

const Login = () => {
  const { t } = useTranslation();
  const { login } = useAuth();

  const { mutate: userLogin, isPending, error, data } = useLogin();

  // Get invitationCastAPI from the local storage
  const { token = null, organization } =
    getFromStorage("invitationCastAPI") ?? {};

  useEffect(() => {
    document.title = t("castAPI Log in - Where AI meets API mocking");
    document.description = t(
      "Log in to leverage AI-powered response generation, intelligent suggestions, and advanced mocking tools for your API development needs."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (!data) return;
    login(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLoginAction = (formData) => {
    userLogin({
      email: formData.email,
      password: formData.password,
      invite: token,
    });
  };

  return (
    <div className="container-fluid px-3">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
          <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
            <div className="d-none d-lg-flex justify-content-between">
              <Link to={routes.auth.home} aria-label="Home">
                <img src={logo} alt="castAPI" width={180} height={45} />
              </Link>
            </div>
          </div>
          {error && (
            <Alert variant="danger" title={t("Oops")} position="top-end">
              {parseError(error)?.message}
            </Alert>
          )}
          <div style={{ maxWidth: "32rem" }}>
            <h1 className="mb-5 fw-bold">
              <span className="d-block">
                Here is where <span className="text-primary">AI</span>
              </span>
              <span className="mt-1">
                <span className="text-primary me-2">meets</span>
                API mocking
              </span>
              <span className="ms-1"> 🚀</span>
            </h1>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-0 lead">Easy to use</h3>
                  <span>Spin up a collection with just a few clicks.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-0 lead">AI powered</h3>
                  <span>Get recommendations for your API responses.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-0 lead">It's free</h3>
                  <span>Start mocking APIs for free.</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
          <div className="w-100" style={{ maxWidth: "28rem" }}>
            <div>
              <div className="d-flex d-lg-none justify-content-center mb-5">
                <Link to={routes.auth.home}>
                  <img src={logo} alt="castAPI" width={180} height={45} />
                </Link>
              </div>
              <div className="mb-4 text-center text-md-start">
                <span className="d-inline-block d-lg-block h1 mb-3 mb-lg-6 me-3">
                  👋
                </span>
                <h2 className="d-inline-block d-lg-block h2 fw-bolder">
                  {organization
                    ? t("Hey! Log in to {{organization}}", {
                        organization: organization,
                      })
                    : t("Nice to see you!")}
                </h2>
              </div>
            </div>

            <form onSubmit={handleSubmit(handleLoginAction)}>
              <Input
                label={t("Email address")}
                name="email"
                type="email"
                register={register}
                errors={errors}
                autoComplete="email"
                maxLength={255}
              />

              <InputPassword
                label={t("Password")}
                name="password"
                type="password"
                register={register}
                errors={errors}
                autoComplete="current-password"
                minLength={6}
              />

              <div className="mt-5 d-grid">
                <Button
                  type="submit"
                  isLoading={isPending}
                  isDisabled={isPending || !isValid}
                  variant={"primary"}
                  size={"lg"}
                >
                  <span>{t("Log in")}</span>
                </Button>
              </div>
            </form>

            <small className="divider-center d-flex align-items-center text-muted mt-4">
              {t("or")}
            </small>

            <div className="d-grid mt-4">
              <GitHubLogin />
            </div>

            <p className="text-muted mt-5 text-center text-md-start">
              <span>{t("Don't have an account yet?")}</span>
              <Link className="ms-1 text-muted" to={routes.public.signup}>
                {t("Sign up here")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

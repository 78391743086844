import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import SidebarFull from "../components/Sidebar/SidebarFull";
import Footer from "../components/Footer/Footer";
import { AccountProvider } from "../providers/AccountProvider";
import routes from "../configs/routes";

const MainLayout = () => {
  const { session } = useAuth();
  const token = localStorage.getItem("sessionCastAPI");

  if (!session || !token) {
    // User is not authenticated, redirect to the sign-in page
    return <Navigate to={routes.public.login} replace />;
  }

  return (
    <AccountProvider>
      <SidebarFull />
      <main className="main min-vh-100">
        <div className="container p-4 mb-5">
          <Outlet />
        </div>
      </main>
      <Footer />
    </AccountProvider>
  );
};

export default MainLayout;

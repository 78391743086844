import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchList } from "../../services/collectionService";
import Options from "../../components/Collections/Item/Options";
import ButtonLink from "../../components/Button/ButtonLink";
import Heading from "../../components/Heading/Heading";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import { Empty, Loading } from "../../components/Collections/Messages";
import Error from "../../components/Errors/Error";
import routes from "../../configs/routes";
import { apiDomain } from "../../utils/helpers";
import "./Collection.scss";

const CollectionList = () => {
  const { t } = useTranslation();
  const { data, isPending, error } = useFetchList();
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data || isPending) return;
    setCollections(data);
    setLoading(false);
  }, [data, isPending]);

  return (
    <>
      <Heading
        back={t("Home")}
        to={routes.auth.home}
        title={t("Collections")}
        actionButtons={
          collections?.length > 0 && (
            <ButtonLink href={routes.auth.collection.create}>
              <i className="ti ti-square-rounded-plus fs-4"></i>
              <span>{t("Create collection")}</span>
            </ButtonLink>
          )
        }
      />
      {error ? (
        <Error
          title={t("Something went wrong")}
          description={t("We are unable to fetch the collections right now.")}
          target={routes.auth.home}
          button={t("Back to home")}
        />
      ) : loading ? (
        <Loading />
      ) : (
        <div className="collections">
          {collections.length === 0 && !loading ? (
            <Empty />
          ) : (
            collections.map((collection) => (
              <div key={collection.uuid} className="mb-3">
                <div className="collection__item card p-4 d-flex">
                  <div className="row">
                    <div className="col-10">
                      <h5 className="mb-1">{collection.name}</h5>
                      {collection.description && (
                        <div className="text-muted small">
                          {collection.description}
                        </div>
                      )}
                      <div className="position-relative z-2 mt-2 d-inline-block w-auto">
                        <div className="bg-light py-1 px-2 rounded">
                          <CopyToClipboard
                            label={
                              <span className="font-monospace">
                                {apiDomain(collection.domain).replace(
                                  /^https?:\/\//,
                                  ""
                                )}
                              </span>
                            }
                            value={apiDomain(collection.domain)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-end align-items-center pe-0 z-3">
                      <p className="me-3 mb-0 text-muted d-flex gap-1">
                        {collection.endpointsCount === "1" ? (
                          <>
                            <span className="fw-semibold">
                              {collection.endpointsCount}
                            </span>
                            <span>{t("endpoint")}</span>
                          </>
                        ) : collection.endpointsCount === "0" ? (
                          <span>{t("No endpoints yet")}</span>
                        ) : (
                          <>
                            <span className="fw-semibold">
                              {collection.endpointsCount}
                            </span>
                            <span>{t("endpoints")}</span>
                          </>
                        )}
                      </p>
                      <Options uuid={collection.uuid} />
                    </div>
                    <Link
                      to={routes.auth.endpoint.list(collection.uuid)}
                      className="stretched-link z-1"
                    ></Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default CollectionList;

export default function YamlPlaceholder({ endpointCount = 1 }) {
  // Helper function to generate endpoint structure
  const generateEndpointStructure = (basePath, method) => [
    { indent: 2, width: 40 }, // Path
    { indent: 4, width: 30 }, // Method
    { indent: 6, width: 50 }, // Summary
    { indent: 6, width: 40 }, // Tags
    { indent: 8, width: 30 }, // Tag name
    { indent: 6, width: 40 }, // Responses
    { indent: 8, width: 35 }, // Status code
    { indent: 10, width: 45 }, // Description
    { indent: 10, width: 40 }, // Content
    { indent: 12, width: 60 }, // Schema reference
  ];

  // Generate the complete structure
  const generateStructure = () => {
    const baseStructure = [
      { indent: 0, width: 30, wave: true }, // openapi: 3.0.0
      { indent: 0, width: 20 }, // info:
      { indent: 2, width: 70, wave: true }, // title
      { indent: 2, width: 80, wave: true }, // description
      { indent: 2, width: 40 }, // version
      { indent: 0, width: 20 }, // servers:
      { indent: 2, width: 90, wave: true }, // url
      { indent: 2, width: 60 }, // description
      { indent: 0, width: 20 }, // paths:
    ];

    // Add endpoints
    const endpoints = [];
    for (let i = 0; i < endpointCount; i++) {
      endpoints.push(...generateEndpointStructure());
    }

    // Add components section
    const componentsStructure = [
      { indent: 0, width: 30 }, // components:
      { indent: 2, width: 25 }, // schemas:
      { indent: 4, width: 70, wave: true }, // schema name
      { indent: 6, width: 40 }, // type: object
      { indent: 6, width: 35 }, // properties:
      { indent: 8, width: 55, wave: true }, // property
    ];

    return [...baseStructure, ...endpoints, ...componentsStructure];
  };

  const structure = generateStructure();

  return (
    <div className="card px-4 rounded">
      {structure.map((line, index) => (
        <div key={index} className="d-flex align-items-center mb-2">
          {/* Indentation */}
          <span style={{ width: `${line.indent * 12}px` }} />

          {/* Line content */}
          <span
            className={`placeholder ${
              line.wave ? "rounded placeholder-wave" : "placeholder-glow"
            } col-${Math.min(12, Math.ceil(line.width / 8))}`}
            style={{
              height: "0.9rem"
            }}
          />
        </div>
      ))}
    </div>
  );
}

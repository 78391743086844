import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchList } from "../../services/endpointService";
import { parseError } from "../../utils/helpers";
import routes from "../../configs/routes";
import { Empty, Loading } from "./Messages";
import List from "./Views/List";
import Heading from "../../components/Heading/Heading";
import Error from "../../components/Errors/Error";
import CollectionNav from "../../components/Heading/CollectionNav";
import ShareModal from "../../components/Collections/Item/ShareModal";

const EndpointList = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { data, isPending, error, refetch } = useFetchList(uuid);

  const [collection, setCollection] = useState({});
  const [endpoints, setEndpoints] = useState([]);
  const [originalEndpoints, setOriginalEndpoints] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data || isPending) return;
    const { content: collection = {} } = data || {};
    const { endpoints = [] } = collection || {};

    setCollection(collection);
    setEndpoints(endpoints);
    setOriginalEndpoints(endpoints);
    setLoading(false);
  }, [data, isPending]);

  const handleSearching = (results) => {
    setEndpoints(results);
  };

  if (error && parseError(error).code === 404) {
    return (
      <Error
        title={t("Collection not found")}
        description={t("The collection you are looking for does not exist.")}
        target={routes.auth.collections}
        button={t("Back to collections")}
      />
    );
  }

  return (
    <>
      <Heading
        to={routes.auth.collections}
        back={t("Collection list")}
        title={collection?.name}
        actionButtons={<ShareModal collection={collection} />}
      />
      <CollectionNav uuid={uuid} />
      {error ? (
        <Error
          title={t("Something went wrong")}
          description={t(
            "We are unable to fetch the endpoints for this collection."
          )}
          target={routes.auth.collections}
          button={t("Back to collections")}
        />
      ) : loading ? (
        <Loading />
      ) : originalEndpoints?.length === 0 && !loading ? (
        <Empty collection={collection} onRefresh={refetch} />
      ) : (
        <List
          collection={collection}
          originalEndpoints={originalEndpoints}
          endpoints={endpoints}
          onSearch={handleSearching}
          onRefresh={refetch}
        />
      )}
    </>
  );
};

export default EndpointList;

import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue = null) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      return value !== null ? JSON.parse(value) : defaultValue;
    } catch (err) {
      console.error("Error reading localStorage key:", keyName, err);
      return defaultValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Save to state
      setStoredValue(valueToStore);

      // Save to localStorage
      if (valueToStore === null) {
        window.localStorage.removeItem(keyName);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(valueToStore));
      }
    } catch (err) {
      console.error("Error setting localStorage key:", keyName, err);
    }
  };

  return [storedValue, setValue];
};

import {
  CollectionList,
  CollectionForm,
  OpenApi,
} from "../../pages/Collections";
import routes from "../../configs/routes";

export const CollectionRoutes = [
  {
    path: routes.auth.collections,
    Component: CollectionList,
  },
  {
    path: routes.auth.collection.create,
    Component: CollectionForm,
  },
  {
    path: routes.auth.collection.edit(":uuid"),
    Component: CollectionForm,
  },
  {
    path: routes.auth.collection.openApi(":uuid"),
    Component: OpenApi,
  },
];

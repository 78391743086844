import React from "react";
import { getFullName, getInitials } from "../../utils/helpers";

const AccountCard = ({ firstName, lastName, avatar, detail }) => {
  const fullName = getFullName(firstName, lastName);
  const initials = getInitials(firstName, lastName);

  return (
    <div className="account__acard">
      {!firstName ? (
        <div className="d-flex align-items-center">
          <div className="icon avatar placeholder-wave">
            <div
              className="placeholder"
              style={{ width: "32px", height: "32px" }}
            />
          </div>
          <div className="ms-2 placeholder-wave col-6">
            <div className="placeholder w-100 rounded"></div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="icon avatar avatar__small bg-secondary d-flex align-items-center justify-content-center">
              {avatar ? (
                <img
                  className="img-fluid"
                  src={avatar}
                  alt={fullName}
                  width={40}
                  height={40}
                  decode="async"
                  loading="lazy"
                />
              ) : (
                <div className="text-light">{initials}</div>
              )}
            </div>
          </div>
          <div className="ms-2 text-start text-truncate">
            <div className="fw-normal mb-0 text-truncate">{fullName}</div>
            {detail && <div className="text-muted small">{detail}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountCard;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGithub } from "../../services/authService";
import { useAuth } from "../../providers/AuthProvider";
import { useLinkGithub } from "../../services/userService";
import github from "../../assets/images/github.svg";
import icon from "../../assets/logos/icon.svg";
import route from "../../configs/routes";
import { getFromStorage } from "../../utils/helpers";

const AuthStatus = {
  INITIAL: "initial",
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending",
};

const REDIRECT_DELAY = 3000;

const StatusMessage = ({ status, isLinking }) => {
  const messages = {
    [AuthStatus.SUCCESS]: {
      icon: "ti ti-rosette-discount-check fs-4 text-success",
      text: "Authenticated successfully!",
    },
    [AuthStatus.ERROR]: {
      icon: "ti ti-exclamation-circle fs-4 text-danger",
      text: isLinking
        ? "Failed to link account! Redirecting to settings..."
        : "Authentication failed! Redirecting to login...",
    },
    [AuthStatus.PENDING]: {
      spinner: true,
      text: "Authenticating account...",
    },
  };

  const currentMessage = messages[status];
  if (!currentMessage) return null;

  return (
    <div className="d-flex align-items-center gap-2 justify-content-center">
      {currentMessage.spinner ? (
        <div className="spinner-border spinner-border-sm" />
      ) : (
        <i className={currentMessage.icon} />
      )}
      <span>{currentMessage.text}</span>
    </div>
  );
};

const GitHubCallback = () => {
  const navigate = useNavigate();
  const { session, login } = useAuth();
  const { mutate: loginGithub, isPending, error, data } = useGithub();
  const {
    mutate: linkGithub,
    isPending: isLinkPending,
    error: linkError,
    isSuccess: linkSuccess,
  } = useLinkGithub();

  const [authStatus, setAuthStatus] = useState(AuthStatus.INITIAL);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = new URLSearchParams(window.location.search).get("code");

        if (!code) {
          setAuthStatus(AuthStatus.ERROR);
          setTimeout(() => navigate(route.public.login), REDIRECT_DELAY);
          return;
        }
        setAuthStatus(AuthStatus.PENDING);

        if (session) {
          linkGithub({ code });
        } else {
          // Get invitationCastAPI from the local storage
          const { token = null } = getFromStorage("invitationCastAPI") ?? {};
          loginGithub({ code, invite: token });
        }
      } catch (err) {
        setAuthStatus(AuthStatus.ERROR);
        setTimeout(() => navigate(route.public.login), REDIRECT_DELAY);
      }
    };
    handleCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, session]);

  useEffect(() => {
    if (isPending || isLinkPending) {
      setAuthStatus(AuthStatus.PENDING);
    }
  }, [isPending, isLinkPending]);

  useEffect(() => {
    if (!data) return;
    setAuthStatus(AuthStatus.SUCCESS);
    login(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, login]);

  useEffect(() => {
    if (linkSuccess) {
      setAuthStatus(AuthStatus.SUCCESS);
      navigate(route.auth.account.overview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkSuccess]);

  useEffect(() => {
    if (error || linkError) {
      setAuthStatus(AuthStatus.ERROR);
      const redirectPath = session
        ? route.auth.account.overview
        : route.public.login;
      setTimeout(() => navigate(redirectPath), REDIRECT_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, linkError, session]);

  return (
    <div className="bg-light min-vh-lg-100">
      <div className="d-flex p-5 justify-content-center align-items-center min-vh-lg-100">
        <div className="col-sm-12 col-lg-6">
          <div className=" card p-5 m-auto text-center">
            <div className="d-flex justify-content-center align-items-center gap-2 mb-5">
              <img src={github} alt="GitHub" width={50} height={50} />
              <i className="ti ti-arrow-right-dashed fs-1 text-primary" />
              <img src={icon} alt="castAPI" width={50} height={50} />
            </div>
            <StatusMessage status={authStatus} isLinking={!!session} />
            {error && (
              <div className="text-danger text-center mt-4" role="alert">
                {error.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GitHubCallback;

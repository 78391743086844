import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAccount } from "../../providers/AccountProvider";
import {
  useUpdateOrganization,
  useFetchOrganization,
} from "../../services/organizationService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Input from "../../components/Forms/Input";
import OrganizationNav from "./OrganizationNav";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import Tooltip from "../../components/Tooltip/Tooltip";

const validationSchema = Yup.object().shape({
  organization: Yup.string().required("Your organization needs a name."),
});

const OrganizationGeneral = () => {
  const { t } = useTranslation();
  const {
    mutate: update,
    error,
    isPending: isUpdatePeding,
    isSuccess,
  } = useUpdateOrganization();
  const { data, isPending: isFetchPending } = useFetchOrganization();
  const { isOrganizationRole } = useAccount();

  const [showEdit, setShowEdit] = useState(false);
  const [organization, setOrganization] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (data) {
      const { content } = data;
      setOrganization(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setShowEdit(false);
    }
  }, [isSuccess]);

  const handleSaveAction = (formData) => {
    update({
      organization: {
        name: formData.organization,
      },
      uuid: organization.uuid,
    });
  };

  const toogleEdit = () => {
    setShowEdit(!showEdit);
  };

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Organization")}
      />
      {error && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      {isSuccess && (
        <Alert variant="success" title="Updated">
          <span>{t("Organization has been updated successfully.")}</span>
        </Alert>
      )}
      <OrganizationNav />
      <section className="pt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-buildings fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Overview")}</h3>
            </div>
            <p className="small">
              {t("Here you can manage your organization information.")}
            </p>
          </div>
          <div className="col-lg-8">
            <form onSubmit={handleSubmit(handleSaveAction)}>
              <div
                className={`align-items-center justify-content-between bg-light p-4 rounded ${
                  showEdit ? "d-none" : "d-flex"
                }`}
              >
                <div>
                  <div className="form-label">{t("Organization name")}</div>
                  <div style={{ margin: "10px 13px 11px 13px" }}>
                    {isFetchPending ? (
                      <div
                        className="placeholder rounded"
                        style={{ width: "200px", height: "18px" }}
                      >
                        <div className="placeholder-glow col-12"></div>
                      </div>
                    ) : (
                      organization?.name
                    )}
                  </div>
                </div>
                {isOrganizationRole("owner") && (
                  <Tooltip message={t("Edit name")} placement="top">
                    <div>
                      <Button
                        type="button"
                        variant="transparent btn-icon icon d-flex align-items-center"
                        onClick={toogleEdit}
                        isDisabled={isFetchPending || isUpdatePeding}
                      >
                        <i className="ti ti-edit fs-4"></i>
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </div>
              <div
                className={`align-items-center justify-content-between bg-light p-4 rounded ${
                  !showEdit ? "d-none" : "d-flex"
                }`}
              >
                <div className="d-flex gap-3">
                  <Input
                    label={t("Organization name")}
                    name="organization"
                    register={register}
                    errors={errors}
                    autoComplete="organization"
                    defaultValue={organization?.name}
                  />
                  <div style={{ marginTop: "24px" }}>
                    <Button
                      type="submit"
                      variant="primary"
                      isLoading={isUpdatePeding}
                      isDisabled={isUpdatePeding || !isValid}
                    >
                      <i className="ti ti-check fs-5"></i>
                      <span>{t("Update")}</span>
                    </Button>
                  </div>
                </div>
                <Tooltip message={t("Cancel")} placement="top">
                  <div>
                    <Button
                      type="button"
                      variant="icon d-flex align-items-center p-2 border-0"
                      onClick={toogleEdit}
                      isDisabled={isUpdatePeding}
                    >
                      <i className="ti ti-square-rounded-x fs-4 text-danger"></i>
                    </Button>
                  </div>
                </Tooltip>
              </div>
              <div className="mt-3 card p-3 rounded">
                <div>
                  <h6>{t("Subscription")}</h6>
                  <p className="mb-1">
                    <span>Your organization is currently subscribed to</span>
                    <span className="ms-1 fw-semibold">Free</span>.
                  </p>
                  <p>
                    Subscribe to one of the our plans to get access to all
                    features and benefits.
                  </p>
                  <div className="position-relative d-inline-block">
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger z-3">
                      Soon
                    </span>
                    <Link
                      to={routes.auth.organization.overview}
                      className="btn btn-secondary disabled"
                      disabled
                    >
                      {t("Upgrade organization")}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrganizationGeneral;

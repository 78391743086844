import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import NewEndpointOptions from "../Views/NewEndpointOptions";

const Empty = ({ collection, onRefresh }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isFlash, setIsFlash] = useState(false);

  useEffect(() => {
    // Extract the message from URL parameters
    const params = new URLSearchParams(location.search);
    const onboard = params.get("onboard");

    if (onboard) {
      setIsFlash(true);

      // Remove the message parameter from the URL
      params.delete("onboard");
      navigate(
        { pathname: location.pathname, search: params.toString() },
        { replace: true }
      );
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location?.state?.refresh) {
      onRefresh();
    }
  }, [location, onRefresh]);

  return (
    <div className="mt-5 text-center">
      {isFlash && (
        <div className="mb-3">
          <h3 className="fw-semibold">
            <span>{t("Your collection has been created!")}</span>
            <span> 🎉</span>
          </h3>
        </div>
      )}

      <h4 className="mb-2">{t("No endpoints in this collection yet")}</h4>
      <p className="lead">
        <span>🚀 </span>
        <span>{t("Choose one of the options below to get started")}</span>
      </p>
      <NewEndpointOptions collection={collection} />
    </div>
  );
};

export default Empty;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchWidgetEndpoints } from "../../../services/endpointService";
import { useFetchList } from "../../../services/collectionService";
import { Link } from "react-router-dom";
import routes from "../../../configs/routes";
import StatusBadge from "../../../pages/Endpoints/Item/StatusBadge";
import { Modal } from "react-bootstrap";
import Select from "../../Forms/Select";
import InputText from "../../Forms/InputText";
import Textarea from "../../Forms/Textarea";
import Button from "../../Button/Button";

const LatestEndpoints = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: endpoints, isPending, error } = useFetchWidgetEndpoints();
  const { data: collections } = useFetchList(endpoints?.length > 0);

  const [show, setShow] = useState(false);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (collections && collections.length > 0) {
      // Map collections to options for select
      const options = collections.map((collection) => ({
        value: collection.uuid,
        label: (
          <div>
            <div className="fw-semibold">{collection.name}</div>
          </div>
        ),
      }));
      setCollectionOptions(options);
    }
  }, [collections]);

  if (isPending || collections?.length === 0) return null;

  const handleContinue = () => {
    navigate(routes.auth.endpoint.create(formData.collection), {
      state: { initialData: formData },
    });
  };

  const handleSelectedCollection = (value) => {
    setFormData({ ...formData, collection: value });
  };

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleToggleDescription = (action) => {
    if (action === "show") {
      setShowDescription(true);
    } else {
      setShowDescription(false);
      handleInputChange("description", "");
    }
  };

  return (
    <section className="mt-3">
      {error && <div>{error}</div>}
      {endpoints &&
        (endpoints.length > 0 ? (
          <div className="row align-items-stretch">
            <div className="col-lg-9">
              <div className="card p-3 h-100">
                <div className="d-flex align-items-center gap-1">
                  <i className="fs-5 ti ti-star"></i>
                  <h4 className="mb-0 fs-5">Recent endpoints</h4>
                </div>
                <table className="table mt-3 mb-0">
                  <thead hidden>
                    <tr>
                      <th scope="col">{t("Name")}</th>
                      <th scope="col">{t("Method")}</th>
                      <th scope="col">{t("Path")}</th>
                      <th scope="col text-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {endpoints.map((endpoint, index) => (
                      <tr
                        key={`lastest-endpoint-${index}_${endpoint.uuid}`}
                        className="position-relative"
                      >
                        <td className="align-middle">
                          <h6 className="mb-0">{endpoint.name}</h6>
                          {endpoint?.description && (
                            <p className="mt-1 mb-0 text-muted small">
                              {endpoint.description}
                            </p>
                          )}
                        </td>
                        <td className="align-middle text-end">
                          <span
                            className={`request rounded-1 px-2 py-0 method__${endpoint.method}`}
                          >
                            {endpoint.method}
                          </span>
                        </td>
                        <td className="align-middle">
                          <div className="font-monospace">{endpoint.path}</div>
                          <div className="small">
                            <StatusBadge status={endpoint.code} size="fs-6" />
                          </div>
                        </td>
                        <td className="align-middle text-end">
                          <Link
                            to={routes.auth.endpoint.edit(endpoint.uuid)}
                            className="text-decoration-none stretched-link"
                          >
                            <i className="ti ti-arrow-right text-primary fs-4"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-stretch">
              <div className="rounded bg-light d-flex align-items-center">
                <div className="text-center p-4">
                  <p className="mb-0">
                    Quickly create an endpoint to an existing collection.
                  </p>
                  <div className="mt-3 m-auto">
                    <button className="btn btn-white" onClick={handleShow}>
                      <div className="d-flex align-items-center gap-1">
                        <i className="ti ti-square-rounded-plus fs-4"></i>
                        <span>Create endpoint</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rounded p-4 bg-light text-center">
            <h5>Create your first endpoint</h5>
            <p className="mb-0">
              You can quickly create an endpoint to an existing collection.
            </p>
            <div className="mt-3 m-auto">
              <button className="btn btn-secondary" onClick={handleShow}>
                <div className="d-flex align-items-center gap-1">
                  <i className="ti ti-square-rounded-plus fs-4"></i>
                  <span>Create endpoint</span>
                </div>
              </button>
            </div>
          </div>
        ))}
      <Modal show={show} onHide={handleClose} className="border-0" centered>
        <Modal.Header closeButton className="border-0">
          <h5 className="mb-0">Create an endpoint</h5>
        </Modal.Header>
        <Modal.Body>
          <InputText
            id="name"
            label={t("Name of the endpoint")}
            onChange={(value) => handleInputChange("name", value)}
            autoComplete="off"
            maxLength="64"
            required
          />
          <div className="mt-1">
            {!showDescription ? (
              <div className="2">
                <button
                  type="button"
                  className="px-2 py-1 d-flex align-items-center gap-1 btn btn-transparent"
                  onClick={() => handleToggleDescription("show")}
                >
                  <i className="ti ti-plus fs-6 text-primary"></i>
                  <span>{t("Add description")}</span>
                </button>
                <div className="mt-1 ms-2 d-flex gap-1 text-muted align-items-center">
                  <i className="ti ti-bulb fs-5"></i>
                  <strong className="fw-semibold small">{t("Tip")}:</strong>
                  <span className="small">
                    {t(
                      "A description helps the AI generate better suggestions"
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div className="description__content">
                <Textarea
                  name="description"
                  label={t("Description")}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  rows={2}
                />
                <div className="d-flex justify-content-end">
                  <div>
                    <button
                      type="button"
                      className="px-2 py-1 d-flex align-items-center gap-1 btn btn-transparent btn-sm"
                      onClick={() => handleToggleDescription("hide")}
                    >
                      <i className="ti ti-eraser fs-5 text-danger"></i>
                      <span>{t("Clear and hide")}</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4">
            <Select
              label="Select the collection"
              name="collection"
              options={collectionOptions}
              onChange={handleSelectedCollection}
              className="w-100"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button type="button" className="btn btn-light" onClick={handleClose}>
            Cancel
          </button>
          <Button
            type="button"
            variant={"primary"}
            onClick={handleContinue}
            isDisabled={!formData.collection || !formData.name}
          >
            <span>Continue</span>
            <i className="ti ti-arrow-right fs-5"></i>
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default LatestEndpoints;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../../../providers/AccountProvider";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";
import { useDeleteWorkspace } from "../../../../services/workspaceService";
import routes from "../../../../configs/routes";

const ModalDelete = ({ workspace, show, handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSession, workspaces } = useAccount();
  const { mutate: remove, error, data, isPending } = useDeleteWorkspace();

  const handleClearAll = async () => {
    remove(workspace.uuid);
  };

  useEffect(() => {
    if (!isPending && data?.status) {
      handleClose();

      // Get the next workspace that is not the current one
      const nextWorkspace = workspaces.find((w) => w.uuid !== workspace.uuid);

      // Update the session with the new workspace
      setSession((prev) => ({
        ...prev,
        workspace: nextWorkspace.uuid,
      }));

      // Redirect to workspace list page
      navigate(routes.auth.workspace.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isPending, handleClose]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
        closeButton
        className="d-flex justify-content-between align-items-center border-0"
      ></Modal.Header>
      <Modal.Body className="text-center">
        <i className="ti ti-trash fs-1 text-danger"></i>
        <h5 className="mt-2 mb-3">{t("Delete workspace")}</h5>
        <p className="mb-2">
          {t(
            "This action will delete all the collections, endpoints and requests associated with this workspace."
          )}
        </p>
        <p>
          {t(`Are you sure you want to delete the "{{workspace}}" workspace?`, {
            workspace: workspace.name,
          })}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        {error && (
          <div className="small text-danger" role="alert">
            {t("An error occurred while deleting this workspace.")}
          </div>
        )}
        <div className="d-flex gap-2 justify-content-end">
          <Button variant="white" onClick={handleClose}>
            <span>{t("No, cancel")}</span>
          </Button>
          <Button
            variant="danger"
            isLoading={isPending}
            isDisabled={isPending}
            onClick={handleClearAll}
          >
            <span>{t("Yes, delete it")}</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;

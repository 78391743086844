import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token) => {
  try {
    const decoded = jwtDecode(token);

    if (decoded.exp * 1000 < Date.now()) {
      console.error("Token expired.");
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  const auth = localStorage.getItem("sessionCastAPI");
  if (!auth || auth === "null" || auth === "undefined") {
    return null;
  }

  const { token } = JSON.parse(auth);
  if (token && isTokenValid(token)) {
    return token;
  }
  return null;
};

export const getOrganization = () => {
  const auth = localStorage.getItem("sessionCastAPI");
  if (!auth || auth === "null" || auth === "undefined") {
    return null;
  }
  const { organization } = JSON.parse(auth);
  return organization || null;
};

export const getWorkspace = () => {
  const auth = localStorage.getItem("sessionCastAPI");
  if (!auth || auth === "null" || auth === "undefined") {
    return null;
  }
  const { workspace } = JSON.parse(auth);
  return workspace || null;
};

export const getAutorization = () => {
  const token = getToken();
  return token ? `Bearer ${token}` : null;
};

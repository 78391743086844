import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiDomain } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import routes from "../../../configs/routes";

const ShareModal = ({ collection }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    const value = apiDomain(collection?.domain);
    navigator.clipboard.writeText(value).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      },
      (err) => setCopySuccess(false)
    );
  };

  return (
    <>
      <button
        className="btn btn-light d-flex align-items-center gap-1"
        onClick={handleShow}
      >
        <i className="ti ti-share fs-5"></i>
        <span>Share</span>
      </button>
      <Modal show={show} onHide={handleClose} className="border-0" centered>
        <Modal.Header closeButton className="border-0">
          <div className="text-primary d-flex align-items-center me-2">
            <i className="ti ti-share fs-3"></i>
          </div>
          <div>
            <h5 className="mb-0">Share to web</h5>
            <small className="d-block text-xs text-muted">
              Publish and share link with anyone
            </small>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-5">
          <div className="d-flex align-items-center mb-3">
            <div>
              {collection.security?.basic ? (
                <p className="mb-0 d-flex align-items-center gap-1">
                  <i className="ti ti-lock fs-5 text-success"></i>
                  <span>Collection protected by</span>
                  <span className="fw-semibold">Basic Auth</span>
                </p>
              ) : (
                <p className="mb-0 d-flex align-items-center gap-1">
                  <i className="ti ti-world fs-5 text-primary"></i>
                  <span>Anyone with this link</span>
                  <span className="fw-semibold">can view</span>
                </p>
              )}
            </div>
            <div className="ms-auto">
              <Link
                to={routes.auth.collection.edit(collection?.uuid)}
                className="small text-decoration-none"
              >
                [settings]
              </Link>
            </div>
          </div>
          <input
            type="url"
            className="form-control"
            readOnly
            value={apiDomain(collection?.domain)}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button type="button" className="btn btn-light" onClick={handleClose}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={copyToClipboard}
          >
            {copySuccess ? (
              <div className="d-flex align-items-center gap-1">
                <i className="ti ti-check fs-5"></i>
                <span>Copied</span>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-1">
                <i className="ti ti-clipboard fs-5"></i>
                <span>Copy link</span>
              </div>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShareModal;

import { featureFlags } from "../configs/features";

export function isFeatureEnabled(featureName) {
  const currentEnv = process.env.REACT_APP_ENV || "development";
  const feature = featureFlags[featureName];

  if (!feature) {
    return false;
  }

  // Check if feature is globally enabled
  if (!feature.enabled) {
    return false;
  }

  // Check environment restrictions
  if (feature.environments && !feature.environments.includes(currentEnv)) {
    return false;
  }

  // Check date restrictions
  const now = new Date();
  if (feature.startDate && now < new Date(feature.startDate)) {
    return false;
  }
  if (feature.endDate && now > new Date(feature.endDate)) {
    return false;
  }

  return true;
}

export function withFeatureFlag(
  WrappedComponent,
  featureName,
  FallbackComponent
) {
  return function FeatureFlaggedComponent(props) {
    if (!isFeatureEnabled(featureName)) {
      return FallbackComponent ? <FallbackComponent {...props} /> : null;
    }
    return <WrappedComponent {...props} />;
  };
}

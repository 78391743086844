import { useTranslation } from "react-i18next";
import { useFetchWidgetCollections } from "../../../services/collectionService";
import { Link } from "react-router-dom";
import routes from "../../../configs/routes";
import { apiDomain } from "../../../utils/helpers";

const LastCollections = () => {
  const { t } = useTranslation();
  const { data: collections, error } = useFetchWidgetCollections();

  return (
    <section>
      {error && <div>{error}</div>}
      {collections &&
        (collections.length > 0 ? (
          <div className="card p-3">
            <div className="d-flex align-items-center gap-1">
              <i className="fs-5 ti ti-clock"></i>
              <h4 className="mb-0 fs-5">Recent collections</h4>
            </div>
            <div className="ms-2">
              {collections.map((collection) => (
                <div key={collection.uuid} className="mt-4 position-relative">
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <h5 className="mb-0 fs-6">{collection.name}</h5>
                      <p className="mt-1 mb-0 text-muted small d-flex gap-1">
                        {collection.endpointsCount === "1" ? (
                          <>
                            <span className="fw-semibold">
                              {collection.endpointsCount}
                            </span>
                            <span>{t("endpoint")}</span>
                          </>
                        ) : collection.endpointsCount === "0" ? (
                          <span>{t("No endpoints yet")}</span>
                        ) : (
                          <>
                            <span className="fw-semibold">
                              {collection.endpointsCount}
                            </span>
                            <span>{t("endpoint")}</span>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <div className="position-relative d-inline-block w-auto">
                        <code className="font-monospace small">
                          {apiDomain(collection.domain).replace(
                            /^https?:\/\//,
                            ""
                          )}
                        </code>
                      </div>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end">
                      <Link
                        to={routes.auth.endpoint.list(collection.uuid)}
                        className="text-decoration-none"
                      >
                        <i className="ti ti-arrow-right text-primary fs-4"></i>
                      </Link>
                    </div>
                  </div>
                  <Link
                    to={routes.auth.endpoint.list(collection.uuid)}
                    className="stretched-link"
                  ></Link>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="rounded bg-light p-5 text-center">
            <h5>Create your first collection</h5>
            <p>
              Collections help you organize related endpoints and share them
              with your team
            </p>
            <div>
              <Link
                to={routes.auth.collection.create}
                className="btn btn-primary"
              >
                <div className="d-flex align-items-center gap-1">
                  <i className="ti ti-plus fs-5"></i>
                  <span>Create a collection</span>
                </div>
              </Link>
            </div>
          </div>
        ))}
    </section>
  );
};

export default LastCollections;

import { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import "./Forms.scss";

export default function Textarea({
  id,
  label,
  defaultValue,
  onChange,
  details,
  defaultLanguage,
  ...props
}) {
  const [value, setValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setErrorMessage(null);
  }, [defaultLanguage]);

  const formatCode = async () => {
    if (editorInstance) {
      try {
        // Get the action
        const action = editorInstance.getAction("editor.action.formatDocument");

        // Execute the format action
        if (action) {
          await action.run();
          // Update the value state with formatted code
          setValue(editorInstance.getValue());
        }
      } catch (error) {
        console.error("Error formatting code:", error);
      }
    }
  };

  const handleEditorDidMount = (editor, monaco) => {
    setEditorInstance(editor);

    // Add keyboard shortcut for formatting (usually Alt+Shift+F)
    editor.addCommand(
      monaco.KeyMod.Alt | monaco.KeyMod.Shift | monaco.KeyCode.KEY_F,
      () => {
        formatCode();
      }
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleEditorValidation = (markers) => {
    if (markers.length === 0) {
      setErrorMessage(null);
      return;
    }
    const { message } = markers[0];
    const line = markers[0].startLineNumber;

    setErrorMessage(`Line ${line}: ${message}`);
  };

  const handleBlur = () => {
    formatCode();
  };

  return (
    <div className="mb-3">
      <label htmlFor={`${id}-editor`} className="form-label">
        {label}
      </label>
      <div role="textbox" className="monaco-editor-container">
        <Editor
          key={`${id}-editor`}
          onChange={handleChange}
          height="300px"
          value={value}
          language={defaultLanguage}
          defaultValue={defaultValue}
          className="form-control p-0"
          onValidate={handleEditorValidation}
          onMount={handleEditorDidMount}
          onBlur={handleBlur}
          options={{
            minimap: { enabled: false },
            selectionHighlight: false,
            scrollBeyondLastLine: false,
            renderLineHighlight: "none",
            tabIndex: 0,
            fontSize: 13,
            readOnly: false,
            wordWrap: "on",
            lineNumbers: "on",
            renderIndentGuides: true,
            formatOnPaste: true,
            formatOnType: true,
            autoFormatOnPaste: true,
            ariaLabel: label || "Code editor",
          }}
          {...props}
        />
      </div>
      {details && <div className="form-text small">{details}</div>}
      {errorMessage && (
        <div className="form-text text-danger small">{errorMessage}</div>
      )}
    </div>
  );
}

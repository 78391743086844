import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DeleteDropdown from "./Delete/DropdownDelete";
import routes from "../../../configs/routes";

const Options = ({ uuid }) => {
  const { t } = useTranslation();
  return (
    <DropdownButton
      as={ButtonGroup}
      key={uuid}
      id={`dropdown-${uuid}`}
      variant="transparent icon d-flex justify-content-center align-items-center p-2"
      title={<i className="ti ti-dots-vertical fs-4 text-muted"></i>}
    >
      <Link
        to={routes.auth.collection.edit(uuid)}
        className="dropdown-item option-edit z-2"
        role="button"
      >
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center">
            <i className="ti ti-edit fs-4"></i>
          </div>
          <div>
            <strong className="d-block fw-semibold">{t("Edit")}</strong>
            <small className="d-block">{t("Update this collection")}</small>
          </div>
        </div>
      </Link>
      <Dropdown.Divider />
      <DeleteDropdown collectionUuid={uuid} />
    </DropdownButton>
  );
};

export default Options;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../../../providers/AccountProvider";
import Button from "../../../../components/Button/Button";
import ModalDelete from "./ModalDelete";
import Tooltip from "../../../../components/Tooltip/Tooltip";

const DeleteAction = ({ workspace }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { workspaces } = useAccount();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log("workspaces", workspaces, workspaces?.length);

  return workspaces?.length > 1 ? (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="ti ti-trash fs-4"></i>
        <span>{t("Delete workspace")}</span>
      </Button>
      <ModalDelete
        workspace={workspace}
        show={show}
        handleClose={handleClose}
      />
    </>
  ) : (
    <Tooltip message={t("You cannot delete the last workspace.")}>
      <div>
        <Button variant="danger" isDisabled={true}>
          <i className="ti ti-trash fs-4"></i>
          <span>{t("Delete workspace")}</span>
        </Button>
      </div>
    </Tooltip>
  );
};

export default DeleteAction;

import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./SearchBox.scss";

const SearchBox = ({ data, onSearch, itemFilter = [], placeholder = "" }) => {
  const [focus, setFocus] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        document.querySelector(".search-box input")?.focus();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    const searchableFields = itemFilter.length > 0 ? itemFilter : ["name"];

    const filteredData = data.filter((item) => {
      return searchableFields.some((key) => {
        const fieldValue = item[key];

        // Handle different data types
        if (fieldValue == null) return false;

        const searchValue = String(fieldValue).toLowerCase();
        const queryValue = value.toLowerCase();

        return searchValue.includes(queryValue);
      });
    });

    onSearch(filteredData);
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    document.querySelector(".search-box input").focus();
    setFocus(true);
  };

  return (
    <div
      onClick={handleInputClick}
      className={`search-box position-relative ${
        focus || query ? "on-focus" : "kbd-ready"
      }`}
    >
      <div className="search-box__icon position-absolute start-0">
        <i className="ti ti-search fs-4"></i>
      </div>
      <Form.Control
        type="search"
        id="search"
        value={query}
        onChange={handleInputChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoComplete="off"
        placeholder={placeholder ? placeholder : "Search..."}
      />

      <div
        className={`search-box__shortcut position-absolute end-0 fade ${
          focus ? "kbd" : "show"
        }`}
      >
        <kbd className="text-muted">⌘ + k</kbd>
      </div>
    </div>
  );
};

export default SearchBox;

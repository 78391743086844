import {
  useState,
  useCallback,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/token";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useFetchUser } from "../services/userService";
import routes from "../configs/routes";

const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const navigate = useNavigate();
  const [session, setSession] = useLocalStorage("sessionCastAPI", null);
  const { data, error } = useFetchUser();

  const [user, setUser] = useState({});
  const [workspaces, setWorkspaces] = useState([]);
  const [organization, setOrganization] = useState({});
  const [permissions, setPermissions] = useState({});

  const logout = useCallback(() => {
    setSession(null);
    localStorage.removeItem("sessionCastAPI");
    navigate(routes.public.login, { replace: true });
  }, [navigate, setSession]);

  useEffect(() => {
    if (!getToken() || error) {
      logout();
      return;
    }

    if (data) {
      setUser(data.user);
      setWorkspaces(data.workspaces);
      setOrganization(data.organization);
      setPermissions({
        features: new Set(data.features || []),
        plan: data.subscription.plan,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  const isOrganizationRole = (role = "owner") => {
    return organization.role === role;
  };

  const isWorkspaceOwner = (owner) => {
    return owner === user.uuid;
  };

  const hasPlanFeature = (feature) => {
    return permissions.features.has(feature);
  };

  const value = useMemo(
    () => ({
      session,
      setSession,
      account: user,
      workspaces: workspaces,
      organization: organization,
      plan: permissions.plan,
      isOrganizationRole,
      isWorkspaceOwner,
      hasPlanFeature,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, session]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export const useAccount = () => {
  return useContext(AccountContext);
};

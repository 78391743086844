import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";
import { useAccount } from "../../providers/AccountProvider";
import routes from "../../configs/routes";
import { useLogout } from "../../services/authService";
import AccountCard from "../User/AccountCard";

const AccountDropdown = () => {
  const { t } = useTranslation();
  const { logout, session } = useAuth();
  const { account } = useAccount();
  const { mutate: serverLogout } = useLogout();

  const handleLogout = () => {
    serverLogout({
      token: session.token,
    });
    logout();
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="none"
        className="p-0 border-0 w-100"
        id="dropdown-account"
        drop="up"
      >
        {!account?.firstName ? (
          <div className="d-flex align-items-center">
            <div className="icon avatar placeholder-wave">
              <div
                className="placeholder"
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <div className="ms-2 placeholder-wave col-6">
              <div className="placeholder w-100 rounded"></div>
            </div>
          </div>
        ) : (
          <AccountCard
            firstName={account?.firstName}
            lastName={account?.lastName}
            avatar={account.avatar}
          />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-item-text d-flex align-items-center">
          <AccountCard
            firstName={account?.firstName}
            lastName={account?.lastName}
            avatar={account.avatar}
          />
        </div>
        <Dropdown.Divider />
        <Link
          to={routes.auth.workspace.overview}
          className="dropdown-item d-flex align-items-center gap-2 py-2"
        >
          <i className="ti ti-settings-2 fs-5"></i>
          <div>{t("Workspace settings")}</div>
        </Link>
        <Link
          to={routes.auth.organization.overview}
          className="dropdown-item d-flex align-items-center gap-2 py-2"
        >
          <i className="ti ti-buildings fs-5"></i>
          <div>{t("Organization settings")}</div>
        </Link>
        <Link
          to={routes.auth.account.overview}
          className="dropdown-item d-flex align-items-center gap-2 py-2"
        >
          <i className="ti ti-user fs-5"></i>
          <div>{t("Account settings")}</div>
        </Link>
        <Dropdown.Divider />
        <div
          className="dropdown-item d-flex align-items-center gap-2 py-2"
          role="button"
          tabIndex="0"
          onClick={handleLogout}
        >
          <div className="d-flex align-items-center">
            <i className="ti ti-logout text-danger fs-4 fw-normal" />
          </div>
          <div>
            <div className="d-block mb-0 fw-semibold">{t("Logout")}</div>
            <small className="d-block">{t("Exit from the app")}</small>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountDropdown;

import { useTranslation } from "react-i18next";
import Heading from "../../components/Heading/Heading";
import routes from "../../configs/routes";
import CreateForm from "./Form/CreateForm";

const WorkspaceCreate = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        back={t("Workspaces")}
        to={routes.auth.workspace.list}
        title={t("Create workspace")}
      />
      <div className="">
        <CreateForm />
      </div>
    </>
  );
};

export default WorkspaceCreate;

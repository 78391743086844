import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGenerateEndpoint } from "../../../../services/aiService";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";
import Textarea from "../../../../components/Forms/Textarea";
import routes from "../../../../configs/routes";

const GenerateEndpoint = ({ uuid }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mutate: generateEndpoint,
    data,
    isPending,
    error,
  } = useGenerateEndpoint();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({});

  const generateContent = () => {
    generateEndpoint(formData);
  };

  useEffect(() => {
    if (data) {
      const { suggestion } = data;
      setShow(false);
      navigate(routes.auth.endpoint.create(uuid), {
        state: { initialData: suggestion },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div
        className="card p-5 position-relative w-100"
        role="button"
        onClick={handleShow}
      >
        <i className="ti bg-primary bg-opacity-10 icon avatar m-auto avatar m-auto p-2 ti-wand fs-3 text-primary"></i>
        <h5 className="mt-3">{t("Create with AI")}</h5>
        <p>{t("Generate an endpoint using AI assistant")}</p>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <div>
            <h5 className="mb-0">{t("Create endpoint with AI")}</h5>
            <small className="text-muted">
              {t(
                "Describe how your endpoint should behave and what you expect from it"
              )}
            </small>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Textarea
            label={t("Endpoint context")}
            name="context"
            onChange={(e) =>
              setFormData({ ...formData, context: e.target.value })
            }
          />
          {error && (
            <div className="text-danger mt-3" role="alert">
              {error}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="white" onClick={handleClose}>
            <span>{t("Cancel")}</span>
          </Button>
          <Button
            variant="primary"
            onClick={generateContent}
            isDisabled={isPending || !formData.context}
          >
            {isPending ? (
              <>
                <i className="ti ti-sparkles fs-5"></i>
                <span>{t("Generating...")}</span>
              </>
            ) : (
              <>
                <i className="ti ti-wand fs-5"></i>
                <span>{t("Generate")}</span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GenerateEndpoint;

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import LastCollections from "../../components/Home/Widgets/LastCollections";
import LatestEndpoints from "../../components/Home/Widgets/LatestEndpoints";

const Home = () => {
  const { t } = useTranslation();
  const { account } = useAccount();

  useEffect(() => {
    document.title = "castAPI Cloud";
  }, []);

  return (
    <div>
      <div className="heading d-flex justify-content-between align-items-center mb-4">
        <div className="heading__title">
          <span className="text-muted d-flex align-items-center small">
            {t("Hello")}
          </span>
          <div className="mt-1">
            <h3 className="mb-0">
              {account?.firstName ? (
                account.firstName
              ) : (
                <div
                  className="placeholder-wave"
                  style={{ width: "80px", height: "29.4px" }}
                >
                  <div className="placeholder rounded w-100 h-100"></div>
                </div>
              )}
            </h3>
          </div>
        </div>
        <div className="heading__buttons"></div>
      </div>

      {/*
      <div className="card p-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div>
              <i className="icon fs-3 p-2 text-white ti ti-credit-card bg-secondary"></i>
            </div>
            <div className="ms-2">
              <p className="mb-1">You are currently subscribed to:</p>
              <h4 className="mb-1">Free</h4>
            </div>
          </div>
          <div className="position-relative d-inline-block">
            <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger z-3">
              Soon
            </span>
            <Link
              to={routes.auth.settings.billing}
              className="btn btn-primary disabled"
              disabled
            >
              <i className="ti ti-click me-2 fs-4"></i>
              <span>{t("Plans overview")}</span>
            </Link>
          </div>
        </div>
      </div>

      <Stats account={account} />
      */}
      <LastCollections />

      <LatestEndpoints />
    </div>
  );
};

export default Home;

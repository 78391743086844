import {
  WorkspaceOverview,
  WorkspaceList,
  WorkspaceCreate,
  WorkspaceEdit,
} from "../../pages/Workspace";
import WorkspaceMembers from "../../pages/Workspace/WorkspaceMembers";
import routes from "../../configs/routes";

export const WorkspaceRoutes = [
  {
    path: routes.auth.workspace.overview,
    Component: WorkspaceOverview,
  },
  {
    path: routes.auth.workspace.list,
    Component: WorkspaceList,
  },
  {
    path: routes.auth.workspace.create,
    Component: WorkspaceCreate,
  },
  {
    path: routes.auth.workspace.settings,
    Component: WorkspaceEdit,
  },
  {
    path: routes.auth.workspace.members,
    Component: WorkspaceMembers,
  },
];

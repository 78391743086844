import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";
import { useFetchUser } from "./userService";

export const useFetchWorkspaces = () => {
  console.log("useFetchWorkspaces");
  return useQuery({
    queryKey: ["workspaces"],
    queryFn: () => fetchWithErrorHandling("/workspace/list"),
  });
};

export const useFetchItem = (uuid) => {
  const { data: userData } = useFetchUser();
  return useQuery({
    queryKey: ["workspace", uuid],
    queryFn: () => fetchWithErrorHandling(`/workspace/${uuid}`),
    enabled: !!uuid && !!userData,
  });
};

export const useUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid, workspace }) =>
      fetchWithErrorHandling(`/workspace/${uuid}/update`, {
        method: "PUT",
        body: JSON.stringify(workspace),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["workspace", variables.uuid],
      });

      queryClient.invalidateQueries({
        queryKey: ["workspaces"],
      });
    },
  });
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (workspace) =>
      fetchWithErrorHandling("/workspace/create", {
        method: "POST",
        body: JSON.stringify(workspace),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workspaces"],
      });
    },
  });
};

export const useDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/workspace/${uuid}/delete`, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["workspaces"],
      });
    },
  });
};

const initialState = {
  name: "",
  description: "",
  path: "/",
  code: 200,
  delay: null,
  method: "GET",
  type: "json",
  headers: { "Content-Type": "application/json" },
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export { initialState, formReducer };

import { SettingsAccount, SettingsSecurity } from "../../pages/Settings";
import routes from "../../configs/routes";

export const AccountRoutes = [
  {
    path: routes.auth.account.overview,
    Component: SettingsAccount,
  },
  {
    path: routes.auth.account.security,
    Component: SettingsSecurity,
  },
];

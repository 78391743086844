import { useState } from "react";
import { useTranslation } from "react-i18next";
import github from "../../assets/images/github.svg";
import Button from "../Button/Button";

const GitHubLogin = ({ buttonText, redirect, isDisabled }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = () => {
    try {
      const redirectUri = process.env.REACT_APP_GITHUB_REDIRECT;
      const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
      setIsLoading(true);

      if (redirect) {
        localStorage.setItem("redirectCastAPI", redirect);
      }

      // Generate a more secure state
      const state = crypto.randomUUID();

      const githubUrl = new URL("https://github.com/login/oauth/authorize");
      githubUrl.searchParams.set("client_id", clientId);
      githubUrl.searchParams.set("redirect_uri", redirectUri);
      githubUrl.searchParams.set("scope", "user:email");
      githubUrl.searchParams.set("state", state);

      // Redirect to GitHub login page
      window.location.href = githubUrl.toString();
    } catch (error) {
      console.error("Error in GitHubLogin: ", error);
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="button"
      onClick={handleLogin}
      variant="white"
      className="d-block w-100"
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      <img src={github} alt="GitHub icon" width={18} height={18} />
      <div>{buttonText ? buttonText : t("Login with GitHub")}</div>
    </Button>
  );
};

export default GitHubLogin;

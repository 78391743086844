import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalDelete from "./ModalDelete";

const DeleteDropdown = ({ collectionUuid }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className="dropdown-item option-delete"
        role="button"
        onClick={handleShow}
      >
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center">
            <i className="ti ti-trash fs-4"></i>
          </div>
          <div>
            <strong className="d-block fw-semibold">{t("Delete")}</strong>
            <small className="d-block">{t("Delete this collection")}</small>
          </div>
        </div>
      </div>
      <ModalDelete
        collectionUuid={collectionUuid}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};

export default DeleteDropdown;

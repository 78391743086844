import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "../../../components/Button/Button";
import { useUnassignMember } from "../../../services/organizationService";
import { useEffect } from "react";

const ModalDelete = ({ memberUuid, show, handleClose }) => {
  const { t } = useTranslation();
  const { mutate: remove, error, data, isPending } = useUnassignMember();

  const handleClearAll = async () => {
    remove(memberUuid);
  };

  useEffect(() => {
    if (!isPending && data?.status) {
      handleClose();
    }
  }, [data, isPending, handleClose]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
        closeButton
        className="d-flex justify-content-between align-items-center border-0"
      ></Modal.Header>
      <Modal.Body className="text-center">
        <i className="ti ti-user-minus fs-1 text-danger"></i>
        <h5 className="mt-2 mb-3">{t("Unassign member")}</h5>
        <p className="mb-2">
          {t(
            "The user will no longer have access to the organization workspaces, collections, and other resources."
          )}
        </p>
        <p>
          {t(
            "Are you sure you want to remove this member from the organizarion?"
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        {error && (
          <div className="small text-danger" role="alert">
            {t("An error occurred while removing the member.")}
          </div>
        )}
        <div className="d-flex gap-2 justify-content-end">
          <Button variant="white" onClick={handleClose}>
            <span>{t("No, cancel")}</span>
          </Button>
          <Button
            variant="danger"
            isLoading={isPending}
            isDisabled={isPending}
            onClick={handleClearAll}
          >
            <span>{t("Yes, unassign")}</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button/Button";
import ModalDelete from "./ModalDelete";

const DeleteAction = ({ endpointUuid }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="ti ti-trash fs-5"></i>
        <span>{t("Delete endpoint")}</span>
      </Button>
      <ModalDelete endpointUuid={endpointUuid} show={show} handleClose={handleClose} />
    </>
  );
};

export default DeleteAction;

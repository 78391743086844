import { getAutorization, getWorkspace } from "../utils/token";

const BASE_URL = process.env.REACT_APP_API_URL;

const handleResponse = async (response, errorMessage) => {
  if (!response.ok) {
    const text = await response.text();

    return Promise.reject(text || errorMessage);
  }
  return response.json();
};

const fetchWithErrorHandling = async (url, options = {}) => {
  options.headers = {
    ...options.headers,
    "Content-Type": "application/json",
    Authorization: getAutorization(),
    Workspace: getWorkspace(),
  };
  try {
    const response = await fetch(BASE_URL + url, options);

    return handleResponse(response, "Something went wrong. Please try again.");
  } catch (error) {
    return Promise.reject(
      "Our service is currently unavailable. Please try again later."
    );
  }
};

export { handleResponse, fetchWithErrorHandling };

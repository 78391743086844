import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";
import { useFetchUser } from "./userService";

export const useInviteByEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ emails }) =>
      fetchWithErrorHandling(`/invite/email`, {
        method: "POST",
        body: JSON.stringify({ emails }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

export const useInviteByLink = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) =>
      fetchWithErrorHandling(`/invite/link`, {
        method: "POST",
        body: JSON.stringify(formData),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

export const useFetchInvites = () => {
  const { data: userData } = useFetchUser();
  return useQuery({
    queryKey: ["invites"],
    queryFn: () => fetchWithErrorHandling(`/invite/list`),
    enabled: !!userData,
  });
};

export const useRevokeInvite = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/invite/${uuid}/revoke`, {
        method: "POST",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

export const useValidateInvite = () => {
  return useMutation({
    mutationFn: (formData) =>
      fetchWithErrorHandling(`/invite/validate`, {
        method: "POST",
        body: JSON.stringify(formData),
      }),
  });
};

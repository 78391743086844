import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useUpdatePassword } from "../../services/userService";
import Heading from "../../components/Heading/Heading";
import routes from "../../configs/routes";
import SettingsNavigation from "./SettingsNavigation";
import Button from "../../components/Button/Button";
import InputPassword from "../../components/Forms/InputPassword";
import Alert from "../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Provide your current password"),
  newPassword: Yup.string()
    .min(6, "Your password must be at least 6 characters")
    .required("You need a password to secure your account"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("You need to confirm your password"),
});

const SettingsSecurity = () => {
  const { t } = useTranslation();
  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdatePassword();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const handleSaveAction = (formData) => {
    update({
      password: {
        old_password: formData.currentPassword,
        password: formData.newPassword,
        confirm_password: formData.confirmPassword,
      },
    });
  };

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Security")}
        actionButtons={<SettingsNavigation />}
      />
      {updateError && (
        <Alert variant="danger" title="Opss!">
          <span>{t(updateError?.message)}</span>
        </Alert>
      )}
      {isUpdateSuccess && (
        <Alert variant="success" title="Updated">
          <span>{t("Your password has been updated successfully.")}</span>
        </Alert>
      )}
      <form className="sub-container" onSubmit={handleSubmit(handleSaveAction)}>
        <div className="card p-4">
          <div className="d-flex align-items-center gap-1 mb-4">
            <i className="ti ti-lock fs-4 text-primary" />
            <h3 className="fs-5 mb-0">{t("Password")}</h3>
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputPassword
                label={t("Current password")}
                name="currentPassword"
                register={register}
                errors={errors}
                autoComplete="current-password"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputPassword
                label={t("New password")}
                name="newPassword"
                register={register}
                errors={errors}
                autoComplete="new-password"
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <InputPassword
                label={t("Confirm password")}
                name="confirmPassword"
                register={register}
                errors={errors}
                autoComplete="new-password"
                required
              />
            </div>
          </div>
        </div>
        <div className="save-button mt-4 d-flex justify-content-end">
          <Button
            type="submit"
            variant="primary"
            isLoading={isUpdatePending}
            isDisabled={isUpdatePending || !isValid}
          >
            <i className="ti ti-device-floppy fs-5"></i>
            <span>{t("Save password")}</span>
          </Button>
        </div>
      </form>
    </>
  );
};

export default SettingsSecurity;

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import routes from "../../configs/routes";

const OrganizationNav = () => {
  const { t } = useTranslation();
  const { organization } = useAccount();
  const currentPaths = window.location.pathname.split("/");

  return (
    <ul className="nav nav-underline border-bottom mb-4">
      <li className="nav-item">
        <Link
          to={routes.auth.organization.overview}
          className={`nav-link px-2 fw-normal ${
            window.location.pathname === routes.auth.organization.overview
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Organization")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.organization.members}
          className={`nav-link px-2 fw-normal ${
            currentPaths.includes("members")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Members")}
        </Link>
      </li>
      {organization?.role && ["admin", "owner"].includes(organization.role) && (
        <li className="nav-item">
          <Link
            to={routes.auth.organization.invite}
            className={`nav-link px-2 fw-normal ${
              currentPaths.includes("invite")
                ? "active cursor-default"
                : "cursor-pointer"
            }`}
          >
            {t("Invites")}
          </Link>
        </li>
      )}
    </ul>
  );
};

export default OrganizationNav;

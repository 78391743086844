import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../../../configs/routes";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";

const Import = ({ collectionUuid }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isDragging, setIsDragging] = useState(false);

  const parseCurl = (curlCommand) => {
    try {
      // Extract the base URL by finding any 'http(s)://' pattern, irrespective of its position
      const urlMatch = curlCommand.match(/https?:\/\/[^\s'"]+/);
      const baseUrl = urlMatch ? urlMatch[0] : null;

      if (!baseUrl) {
        //throw new Error("No URL found in cURL command");
      }

      // Extract method (default to GET, or POST if there's form data)
      let method = curlCommand.match(/-X\s+([A-Z]+)/)?.[1];

      // Extract headers with improved regex for complex values
      const headers = {};
      // Split the curl command into lines
      const lines = curlCommand.split(/\s*\\\s*\n\s*/);

      for (const line of lines) {
        // Check if line contains a header
        if (line.includes("--header") || line.includes(" -H ")) {
          // Adjusted regex to capture headers with nested quotes
          const headerMatch = line.match(
            /(?:--header|-H)\s+['"]?([^'"]+?)['"]?\s*:\s*['"]?((?:[^"']*?(?:"[^"]*?"|'[^']*?'))*[^"']*?)['"]?$/
          );
          if (headerMatch) {
            const headerName = headerMatch[1].trim();
            const headerValue = headerMatch[2].trim();
            headers[headerName] = headerValue;
          }
        }
      }

      // Extract form data
      const formData = {};
      const formMatches = curlCommand.matchAll(
        /--form\s+['"]?([^=]+?)=(.+?)['"]?(?=\s|$)/g
      );
      let hasFormData = false;

      for (const match of formMatches) {
        formData[match[1].trim()] = match[2].trim();
        hasFormData = true;
      }

      // Extract URL-encoded data
      const urlencodedData = {};
      const dataUrlencodeMatches = curlCommand.matchAll(
        /--data-urlencode\s+['"]([^=]+)=([^'"]+)['"]/g
      );

      for (const match of dataUrlencodeMatches) {
        urlencodedData[match[1].trim()] = decodeURIComponent(match[2].trim());
      }

      // If we have form data and no explicit method, it's a POST
      if (!method && (hasFormData || Object.keys(urlencodedData).length > 0)) {
        method = "POST";
      } else if (!method) {
        method = "GET";
      }

      // Detect body type
      let bodyType = "json"; // Default to JSON
      if (hasFormData) {
        bodyType = "form-data";
      } else if (Object.keys(urlencodedData).length > 0) {
        bodyType = "form-url-encoded";
      } else if (headers["Content-Type"]) {
        const contentType = headers["Content-Type"].toLowerCase();
        if (contentType.includes("application/json")) {
          bodyType = "json";
        } else if (contentType.includes("application/x-www-form-urlencoded")) {
          bodyType = "form-url-encoded";
        }
      }

      // Extract body (--data parameter)
      let body = null;
      const dataMatch = curlCommand.match(/--data\s+['"](.+?)['"]/);
      if (dataMatch) {
        body = dataMatch[1];
      } else if (hasFormData) {
        body = JSON.stringify(formData);
      } else if (Object.keys(urlencodedData).length > 0) {
        body = JSON.stringify(urlencodedData);
      }

      // Parse URL and path
      const url = new URL(baseUrl);
      const path = url.pathname;
      const name = baseUrl;

      // Build parameters from URL search params, form data, and URL-encoded data
      const parameters = {};
      url.searchParams.forEach((value, key) => {
        parameters[key] = value;
      });
      if (hasFormData) {
        Object.assign(parameters, formData);
      }
      if (Object.keys(urlencodedData).length > 0) {
        Object.assign(parameters, urlencodedData);
      }

      const result = {
        name,
        method,
        path,
        code: 200,
        type: bodyType,
        body,
        delay: 0,
        headers,
        parameters: Object.keys(parameters).length > 0 ? parameters : null,
      };

      return result;
    } catch (error) {
      console.error("Error parsing cURL:", error);
      throw error;
    }
  };

  const parseUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      return {
        name: parsedUrl.pathname.split("/").pop() || "unnamed",
        method: "GET",
        path: parsedUrl.pathname,
        code: 200,
        type: "json",
        body: null,
        delay: 0,
        headers: {
          "Content-Type": "application/json",
        },
        parameters: Object.fromEntries(parsedUrl.searchParams),
      };
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  const parseContent = (text) => {
    text = text.trim();

    // Check if it's a cURL command
    if (text.toLowerCase().startsWith("curl")) {
      return parseCurl(text);
    }

    // Check if it's a URL
    if (text.match(/^https?:\/\//)) {
      return parseUrl(text);
    }

    // Try parsing as JSON
    try {
      return {
        name: "imported_json",
        method: "POST",
        path: "/",
        code: 200,
        type: "json",
        body: text,
        delay: 0,
        headers: {
          "Content-Type": "application/json",
        },
        parameters: null,
      };
    } catch (e) {
      // If not JSON, treat as raw text
      return {
        name: "raw_text",
        method: "POST",
        path: "/",
        code: 200,
        type: "text",
        body: text,
        delay: 0,
        headers: {
          "Content-Type": "text/plain",
        },
        parameters: null,
      };
    }
  };

  const handleContent = (text) => {
    const parsed = parseContent(text);
    if (parsed) {
      handleClose();

      // Redirect to the create endpoint page with the parsed data
      navigate(routes.auth.endpoint.create(collectionUuid), {
        state: { initialData: parsed },
      });
    }
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);

    const items = e.dataTransfer.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "string") {
          items[i].getAsString(handleContent);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    handleContent(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="card card__action p-5 position-relative w-100"
        role="button"
        onClick={handleShow}
      >
        <i className="ti bg-success bg-opacity-10 icon p-2 ti-upload fs-3 text-success"></i>
        <h5 className="mt-3">{t("Import cURL")}</h5>
        <p>{t("Import from existing cURL request")}</p>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <h5 className="mb-0">{t("Import cURL")}</h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div
            className={`card card__upload bg-light text-center p-5 ${
              isDragging ? "bg-info" : "bg-light"
            }`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onPaste={handlePaste}
            tabIndex={0}
            role="button"
          >
            <i className="ti ti-file-import fs-1 text-primary"></i>
            <h5 className="mt-3 mb-2">{t("Drop it here")}</h5>
            <p className="mb-0">{t("Paste cURL command or URL...")}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="white" onClick={handleClose}>
            <span>{t("Cancel")}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Import;

import { useParams } from "react-router-dom";
import Create from "./Views/Create";
import Edit from "./Views/Edit";

const EndpointForm = () => {
  const { uuid } = useParams();

  return uuid ? <Edit /> : <Create />;
};

export default EndpointForm;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdate,
  useDuplicate,
  useFetchItem,
} from "../../../services/endpointService";
import { formatDate } from "../../../utils/helpers";
import routes from "../../../configs/routes";
import Form from "../Form/BaseForm";
import Button from "../../../components/Button/Button";
import Heading from "../../../components/Heading/Heading";
import Alert from "../../../components/Alert/Alert";
import DeleteAction from "../Item/Delete/ButtonDelete";

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { data: endpoint, isPending, error } = useFetchItem(uuid);
  const [endpointData, setEndpointData] = useState({});

  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdate();

  const {
    data: duplicateData,
    mutate: duplicate,
    error: duplicateError,
    isSuccess: isDuplicateSuccess,
    isPending: isDuplicatePending,
  } = useDuplicate();

  useEffect(() => {
    if (!endpoint) return;
    const { content } = endpoint;
    setEndpointData(content);
  }, [endpoint, error, isPending]);

  useEffect(() => {
    if (isDuplicateSuccess) {
      const { content } = duplicateData;
      navigate(routes.auth.endpoint.edit(content.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateSuccess]);

  const handleUpdate = (formData) => {
    update({
      endpoint: formData,
      uuid: endpointData.uuid,
    });
  };

  const handleDuplicateAction = () => {
    duplicate({
      endpoint: endpointData,
      uuid: endpoint.uuid,
    });
  };

  return (
    <>
      <Heading
        back={t("Endpoints")}
        to={routes.auth.endpoint.list(endpointData.collection_uuid)}
        title={t("Edit endpoint")}
        actionButtons={
          <div className="d-flex gap-2">
            <Button
              variant="white"
              onClick={handleDuplicateAction}
              isDisabled={isDuplicatePending}
              isLoading={isDuplicatePending}
            >
              <i className="ti ti-copy fs-5"></i>
              <span>{t("Duplicate")}</span>
            </Button>
          </div>
        }
      />

      {duplicateError && (
        <Alert variant="danger" title={t("Oopss")}>
          {duplicateError.message}
        </Alert>
      )}

      {isDuplicateSuccess && (
        <Alert variant="success" title={t("Success")}>
          <span>{t("Endpoint duplicated successfully.")}</span>
        </Alert>
      )}

      <Form
        initialData={endpointData}
        onSubmit={handleUpdate}
        error={updateError}
        success={isUpdateSuccess}
        isLoading={isUpdatePending}
        successMessage={t("Endpoint updated successfully.")}
        submitButtonText={t("Save changes")}
        submitButtonIcon="ti-device-floppy"
        uuid={endpointData?.uuid}
      />

      {endpointData && (
        <>
          <section className="border-top pt-4 mt-4">
            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-user-scan fs-4 text-primary" />
                  <h3 className="fs-5 mb-0">{t("Metadata")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This section contains metadata information about the endpoint."
                  )}
                </p>
              </div>
              <div className="col-lg-8 d-flex align-items-center justify-content-end">
                <div className="row w-100">
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Author")}</div>
                    <div className="mt-1">
                      <p className="mb-0">
                        {endpointData?.user?.first_name}{" "}
                        {endpointData?.user?.last_name}
                      </p>
                      <p className="mb-0 small">{endpointData?.user?.email}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Last updated")}</div>
                    <div className="mt-1">
                      {formatDate(endpointData?.updatedAt)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Created on")}</div>
                    <div className="mt-1">
                      {formatDate(endpointData?.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="border-top pt-4 mt-4">
            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-alert-triangle fs-4 text-danger" />
                  <h3 className="fs-5 mb-0">{t("Danger zone")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This action will delete all requests associated with this endpoint."
                  )}
                </p>
              </div>
              <div className="col-lg-8 d-flex align-items-center justify-content-end">
                <div>
                  <DeleteAction endpointUuid={endpointData.uuid} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Create;

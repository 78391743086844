import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchInvites } from "../../services/inviteService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Alert from "../../components/Alert/Alert";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import OrganizationNav from "./OrganizationNav";
import { fullName, formatDate } from "../../utils/helpers";
import OptionsDropdown from "./Members/OptionsDropdown";
import InviteByLink from "./Invite/InviteByLink";

const InviteMembers = () => {
  const { t } = useTranslation();
  const { account, organization } = useAccount();
  const {
    data: invitesData,
    isPending: isFetchPending,
    error: isFetchError,
  } = useFetchInvites();

  if (organization?.role && !["admin", "owner"].includes(organization.role)) {
    window.location.href = routes.auth.organization.overview;
  }

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Invite invites")}
      />
      {isFetchError && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      <OrganizationNav />
      <section className="pt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-link fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Invite by link")}</h3>
            </div>
            <p className="small">
              {t(
                "Generate a link that can be shared with multiple team invites"
              )}
            </p>
          </div>
          <div className="col-lg-8">
            <InviteByLink />
          </div>
        </div>
      </section>
      <section className="pt-4 mt-4 border-top">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-send fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Invite links")}</h3>
            </div>
            <p className="small mb-0">
              {t("List of gereated invites for the organization")}
            </p>
          </div>
          <div className="col-lg-8"></div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Link")}</th>
              <th scope="col">{t("Created by")}</th>
              <th scope="col">{t("Usage")}</th>
              <th scope="col">{t("Expires in")}</th>
              <th scope="col">{t("Status")}</th>
              <th scope="col text-end"></th>
            </tr>
          </thead>
          {invitesData && !isFetchPending && (
            <tbody>
              {invitesData.map((invite, index) => (
                <tr key={`org-invite-${index}_${invite.uuid}`}>
                  <td className="align-middle">
                    <div className="d-flex align-items-center gap-1">
                      <i className="ti ti-link fs-5 text-primary" />
                      <CopyToClipboard
                        label={`/invitation/${invite.token}`}
                        value={`${process.env.REACT_APP_BASE_URL}/invitation/${invite?.token}`}
                      />
                    </div>
                  </td>
                  <td className="align-middle">
                    <div>
                      {fullName(
                        invite.inviter.first_name,
                        invite.inviter.last_name
                      )}
                    </div>
                    <small className="d-block text-muted">
                      {invite.inviter.email}
                    </small>
                  </td>
                  <td className="align-middle">
                    <div className="d-flex align-items-center gap-2">
                      <span>{invite.usage_count}</span>
                      <span>{t("of")}</span>
                      <span>{invite.max_uses}</span>
                    </div>
                  </td>
                  <td className="align-middle">
                    {formatDate(invite.expires_at)}
                  </td>
                  <td className="align-middle">
                    <span className="badge bg-success">{invite.status}</span>
                  </td>
                  <td className="align-middle text-end">
                    {invite.inviter.uuid === account?.uuid && (
                      <div className="d-flex justify-content-end">
                        <OptionsDropdown uuid={invite.uuid} />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </section>
    </>
  );
};

export default InviteMembers;

import { useUpdate } from "../../../services/workspaceService";
import { useTranslation } from "react-i18next";
import BaseForm from "./BaseForm";

const EditForm = ({ workspaceData }) => {
  const { t } = useTranslation();

  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdate();

  console.log(workspaceData);

  const handleUpdate = (formData) => {
    update({
      uuid: workspaceData.uuid,
      workspace: {
        name: formData.name,
        visibility: formData.visibility,
      },
    });
  };

  return (
    <BaseForm
      initialData={workspaceData}
      onSubmit={handleUpdate}
      error={updateError}
      success={isUpdateSuccess}
      isLoading={isUpdatePending}
      successMessage={t("Workpace updated successfully.")}
      submitButtonText={t("Save changes")}
      submitButtonIcon="ti-device-floppy"
      uuid={workspaceData?.uuid}
    />
  );
};

export default EditForm;

import { useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./CopyToClipboard.scss";

const CopyToClipboard = ({ label, value, text }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      },
      (err) => setCopySuccess(false)
    );
  };

  return (
    <div className="copy-to-clipboard d-flex align-items-center">
      <Tooltip
        message={copySuccess ? "Copied!" : text ? text : "Click to copy"}
        placement="top"
      >
        <div
          onClick={copyToClipboard}
          className={copySuccess ? "cursor-default" : "cursor-pointer"}
          aria-label={copySuccess ? "Copied" : "Copy to clipboard"}
        >
          {label ?? value}
        </div>
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../../components/Forms/Input";
import Button from "../../../components/Button/Button";
import Alert from "../../../components/Alert/Alert";
import Select from "../../../components/Forms/Select";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min("3")
    .max("64")
    .required("Your workspace needs a name."),
  visibility: Yup.string()
    .oneOf(
      ["personal", "private", "organization"],
      "Please select a valid visibility option"
    )
    .required("Please select workspace visibility"),
});

const BaseForm = ({
  initialData,
  onSubmit,
  isLoading,
  error,
  success,
  successMessage,
  submitButtonText,
  submitButtonIcon,
  uuid = null,
}) => {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const handleVisibilityChange = (value) => {
    setValue("visibility", value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  useEffect(() => {
    setValue("name", initialData?.name ?? "");
    setValue("visibility", initialData?.visibility || "personal", {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert variant="danger" title={t("Failed")}>
          {t("Something went wrong! Please try again")}
        </Alert>
      )}
      {success && (
        <Alert variant="success" title={t("Updated")}>
          {successMessage}
        </Alert>
      )}
      <section className={uuid ? "border-none pt-4" : "border-top pt-4 mt-4"}>
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-library fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Workspace details")}</h3>
            </div>
            <p className="small">{t("Give your workspace a name")}</p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <Input
                  label={t("Workspace name")}
                  name="name"
                  autoComplete="off"
                  maxLength="64"
                  minLength="3"
                  register={register}
                  errors={errors}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="border-top pt-4 mt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-lock-square fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Visibility")}</h3>
            </div>
            <p className="small">
              {t("Choose who can access your workspace.")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-5">
                <Select
                  defaultValue={initialData?.visibility || "personal"}
                  label={t("Who can access your workspace?")}
                  name="visibility"
                  variant={"white"}
                  className="w-100"
                  onChange={(value) => handleVisibilityChange(value)}
                  register={register}
                  errors={errors}
                  options={[
                    {
                      value: "personal",
                      label: (
                        <div className="text-start d-flex align-items-center gap-2">
                          <i className="ti ti-user fs-4 text-primary" />
                          <div>
                            <span className="d-block">{t("Only me")}</span>
                            <small className="text-start d-block">
                              {t("Personal workspace")}
                            </small>
                          </div>
                        </div>
                      ),
                    },
                    {
                      value: "private",
                      label: (
                        <div className="d-flex align-items-center gap-2 disabled">
                          <i className="ti ti-lock fs-4 text-primary" />
                          <div>
                            <span className="d-block">
                              {t("Only invited members")}
                            </span>
                            <small className="text-start d-block">
                              {t("Private workspace")}
                            </small>
                          </div>
                        </div>
                      ),
                    },
                    {
                      value: "organization",
                      label: (
                        <div className="d-flex align-items-center gap-2 disabled">
                          <i className="ti ti-users fs-4 text-primary" />
                          <div>
                            <span className="d-block">
                              {t("Everyone from {{company}}", {
                                company: "organization",
                              })}
                            </span>
                            <small className="text-start d-block">
                              {t("Team")} (1 {t("member")})
                            </small>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="border-top pt-4 mt-4 d-flex justify-content-end">
        <Button
          variant="primary"
          isLoading={isLoading}
          isDisabled={!isValid || (errors && Object.keys(errors).length > 0)}
        >
          <i className={`ti ${submitButtonIcon} fs-4`}></i>
          <span>{submitButtonText}</span>
        </Button>
      </section>
    </form>
  );
};

export default BaseForm;

import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchItem } from "../../services/workspaceService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import WorkspaceNav from "./WorkspaceNav";
import Visibility from "../../components/Workspace/Visibility";

const WorkspaceOverview = () => {
  const { t } = useTranslation();
  const { session, isWorkspaceOwner } = useAccount();
  const { data: workspace, isPending: isFetchPending } = useFetchItem(
    session.workspace
  );

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Workspace overview")}
      />
      <WorkspaceNav isOwner={isWorkspaceOwner(workspace?.owner_uuid) ?? false } />
      <section className="pt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-settings-2 fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Overview")}</h3>
            </div>
            <p className="small">
              {t("Here you can manage your workspace settings")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="bg-light p-4 rounded">
              <div className="h6">{t("Current selected workspace")}</div>
              {isFetchPending ? (
                <div
                  className="placeholder rounded"
                  style={{ width: "200px", height: "18px" }}
                >
                  <div className="placeholder-glow col-12"></div>
                </div>
              ) : (
                <div>{workspace?.name}</div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 mt-4 border-top">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-lock-square fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Visibility")}</h3>
            </div>
            <p className="small">
              {t("Who can access your workspace and see its content")}
            </p>
          </div>
          <div className="col-lg-8">
            <Visibility visibility={workspace?.visibility} />
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkspaceOverview;

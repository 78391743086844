import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchItem } from "../../services/workspaceService";
import { formatDate } from "../../utils/helpers";
import Heading from "../../components/Heading/Heading";
import routes from "../../configs/routes";
import WorkspaceNav from "./WorkspaceNav";
import EditForm from "./Form/EditForm";
import DeleteAction from "./Item/Delete/ButtonDelete";
import AccountCard from "../../components/User/AccountCard";

const WorkspaceEdit = () => {
  const { t } = useTranslation();
  const { session, isOrganizationRole, isWorkspaceOwner } = useAccount();
  const { data: workspaceData, isPending: isFetchPending } = useFetchItem(
    session.workspace
  );

  if (
    !isOrganizationRole("owner") ||
    !isWorkspaceOwner(workspaceData?.owner?.uuid)
  ) {
    window.location.href = routes.auth.workspace.list;
  }

  return (
    <>
      <Heading
        back={t("Home")}
        to={routes.auth.home}
        title={t("Workspace settings")}
      />
      <WorkspaceNav isOwner={true} />
      <EditForm workspaceData={workspaceData} />
      {!isFetchPending && workspaceData?.uuid && (
        <>
          <section className="border-top mt-4 pt-4">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-user-scan fs-4 text-primary" />
                  <h3 className="fs-5 mb-0">{t("Metadata")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This section contains metadata information about the workspace."
                  )}
                </p>
              </div>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Author")}</div>
                    <div className="mt-1">
                      {}
                      <AccountCard
                        firstName={workspaceData?.owner?.first_name}
                        lastName={workspaceData?.owner?.last_name}
                        email={workspaceData?.owner?.email}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Last updated")}</div>
                    <div className="mt-1">
                      {formatDate(workspaceData?.updatedAt)}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="fw-semibold">{t("Created on")}</div>
                    <div className="mt-1">
                      {formatDate(workspaceData?.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="border-top mt-4 pt-4">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="d-flex align-items-center gap-1 mb-2">
                  <i className="ti ti-alert-triangle fs-4 text-danger" />
                  <h3 className="fs-5 mb-0">{t("Danger zone")}</h3>
                </div>
                <p className="small">
                  {t(
                    "This action will delete all endpoints and requests associated with this workspace."
                  )}
                </p>
              </div>
              <div className="col-lg-8 d-flex justify-content-end">
                <div>
                  <DeleteAction workspace={workspaceData} />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default WorkspaceEdit;

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import EditOption from "./EditOption";
import DeleteOption from "./DeleteOption";

const OptionsDropdown = ({ uuid }) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      key={uuid}
      id={`dropdown-${uuid}`}
      variant="transparent icon d-flex justify-content-center align-items-center p-2"
      title={<i className="ti ti-dots-vertical fs-4 text-muted"></i>}
    >
      <EditOption uuid={uuid} />
      <Dropdown.Divider />
      <DeleteOption uuid={uuid} />
    </DropdownButton>
  );
};

export default OptionsDropdown;

import "./Button.scss";

const Button = ({
  children,
  onClick,
  variant,
  size,
  isLoading,
  isDisabled,
  type,
}) => {
  return (
    <button
      className={`btn btn-${variant || "primary"} btn-${size || "md"}`}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      type={type || "submit"}
    >
      <div className="d-flex justify-content-center align-items-center gap-1">
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm text-muted me-1"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {children}
      </div>
    </button>
  );
};

export default Button;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Item from "../Item/Item";
import SearchBox from "../../../components/SearchBox/SearchBox";
import Button from "../../../components/Button/Button";
import NewEndpointOptions from "./NewEndpointOptions";

const List = ({
  originalEndpoints,
  collection,
  endpoints,
  onSearch,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [showStartOptions, setShowStartOptions] = useState(false);

  useEffect(() => {
    if (location?.state?.refresh) {
      onRefresh();
      setShowStartOptions(false);
    }
  }, [location, onRefresh]);

  const handleShowStartOptions = () => {
    setShowStartOptions(true);
  };

  if (showStartOptions) {
    return (
      <div className="mt-5 text-center">
        <h5>{t("Choose one of the options below to get started")}</h5>
        <NewEndpointOptions collection={collection} endpoints={endpoints} />
        <div className="d-flex justify-content-start mt-4">
          <Button onClick={() => setShowStartOptions(false)} variant="white">
            <i className="ti ti-arrow-left fs-5"></i>
            <span>{t("Cancel")}</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="endpoint__list">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <SearchBox
          itemFilter={["name", "description", "method", "path", "code"]}
          data={originalEndpoints}
          placeholder={t("Name, method, path, code, etc...")}
          onSearch={onSearch}
        />
        <div className="action__buttons">
          <Button onClick={handleShowStartOptions} variant="secondary">
            <i className="ti ti-square-rounded-plus fs-4"></i>
            <span>{t("New endpoint")}</span>
          </Button>
        </div>
      </div>
      {endpoints.length > 0 ? (
        <div>
          {endpoints?.map((endpoint) => (
            <Item key={endpoint.uuid} endpoint={endpoint} />
          ))}
        </div>
      ) : (
        <div className="text-center">
          <h4 className="bold">{t("No endpoint found.")}</h4>
          <p>{t("We couldn't find any endpoint.")}</p>
        </div>
      )}
    </div>
  );
};

export default List;

import React from "react";
import { useTranslation } from "react-i18next";

const OPTIONS = [
  {
    icon: "ti ti-user",
    key: "personal",
    label: "Only me",
    description: "Personal workspace",
  },
  {
    icon: "ti ti-users",
    key: "organization",
    label: "Everyone from organization",
    description: "Organization workspace",
  },
  {
    icon: "ti ti-lock",
    key: "private",
    label: "Only invited members",
    description: "Private workspace",
  },
];

const Visibility = ({ visibility, description, preview }) => {
  const { t } = useTranslation();

  if (!visibility) return null;
  const option = OPTIONS.find((option) => option.key === visibility);

  return (
    <div className="d-flex align-items-center gap-2">
      {preview === "sort" ? (
        description ?? t(option.description)
      ) : (
        <>
          <i className={`${option.icon} fs-4 text-primary`} />
          <div>
            <span className="d-block">{t(option.label)}</span>
            <small className="text-start d-block text-muted">
              {description ?? t(option.description)}
            </small>
          </div>
        </>
      )}
    </div>
  );
};

export default Visibility;

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../configs/routes";
import AccountDropdown from "../Header/AccountDropdown";
import logo from "../../assets/logos/logo.svg";
import WorkspaceSwitcher from "../Workspace/WorkspaceSwitcher";
import "./Sidebar.scss";

const isActive = (pathname, urls) => {
  return urls.some((url) => pathname.startsWith(url));
};

const SidebarFull = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <aside className="sidebar d-none d-lg-block h-100 position-fixed top-0 bottom-0 start-0">
      <div className="d-flex flex-column flex-shrink-0 p-3 min-vh-100">
        <Link
          aria-label="Home"
          to={routes.auth.home}
          className="d-flex align-items-center align-items-center mt-2 mb-4"
        >
          <img src={logo} alt="castAPI" width={140} height={35} />
        </Link>

        <div className="mb-5">
          <WorkspaceSwitcher />
        </div>

        <nav className="nav nav-pills flex-column mb-auto">
          <div className="nav-item mb-3">
            <Link
              to={routes.auth.home}
              className={`nav-link p-2 d-flex align-items-center gap-2 ${
                routes.auth.home === location.pathname
                  ? "active bg-primary bg-opacity-10 text-primary"
                  : "body-text cursor-pointer"
              }`}
              aria-label={t("Home")}
            >
              <i className="ti ti-home fs-3"></i>
              <span>{t("Home")}</span>
            </Link>
          </div>
          <div className="nav-item mb-3">
            <Link
              to={routes.auth.collections}
              className={`nav-link p-2 d-flex align-items-center gap-2 ${
                isActive(location.pathname, [
                  routes.auth.collections,
                  `/collection`,
                ])
                  ? "active bg-primary bg-opacity-10 text-primary cursor-default"
                  : "body-text"
              }`}
            >
              <i className="ti ti-library fs-3"></i>
              <span>{t("Collections")}</span>
            </Link>
          </div>
        </nav>
        <div className="mt-5">
          <AccountDropdown />
        </div>
      </div>
    </aside>
  );
};

export default SidebarFull;

import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../configs/routes";
import logo from "../../assets/logos/logo.svg";
import { useValidateInvite } from "../../services/inviteService";
import { parseError } from "../../utils/helpers";
import Button from "../../components/Button/Button";

const Invitation = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [invitation, setInvitation] = useState(null);

  const {
    mutate: validateInvite,
    data,
    isPending,
    error,
  } = useValidateInvite();

  useEffect(() => {
    document.title = t("Invitation to join an organization");
    if (token) {
      validateInvite({ token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (data) {
      setInvitation(data);
    }
  }, [data]);

  const handleAcceptAction = () => {
    if (invitation) {
      // Set the invitation to the local storage
      localStorage.setItem(
        "invitationCastAPI",
        JSON.stringify({ organization: invitation.organization, token })
      );
      navigate(routes.public.signup);
    }
  };
  if (isPending) {
    return;
  }

  return (
    <div className="bg-light min-vh-lg-100">
      <div className="d-flex p-5 justify-content-center align-items-center min-vh-lg-100">
        <div className="col-lg-6">
          <div className="card p-5">
            <div className="mb-5 d-flex gap-1 justify-content-center">
              <Link to={routes.auth.home} aria-label="Logo button">
                <img src={logo} alt="castAPI logo" width={180} height={45} />
              </Link>
            </div>
            <p className="text-center">
              {t("You have been invited to join an organization")}
            </p>
            <div className="text-center justify-content-center d-flex gap-1">
              <div className="h5 mb-0 text-primary">{invitation?.inviter}</div>
              <div className="h5 mb-0">{t("is inviting you to join")}</div>
              <div className="h5 mb-0 text-primary">
                {invitation?.organization}
              </div>
            </div>

            {error && (
              <div className="text-danger text-center" role="alert">
                {parseError(error).message}
              </div>
            )}

            <div className="d-flex justify-content-center mt-5">
              <div className="text-center">
                <Button
                  isDisabled={!invitation?.inviter}
                  className="btn btn-secondary d-block"
                  onClick={handleAcceptAction}
                >
                  {t("Accept invitation")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;

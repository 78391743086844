import { Routes, Route } from "react-router-dom";
import { publicRoutes } from "./public";
import { authenticatedRoutes } from "./authenticated";
import { OAuthLayout, AuthLayout, MainLayout } from "../layouts";

function AppRoutes() {
  return (
    <Routes>
      {/* OAuth routes */}
      <Route element={<OAuthLayout />}>
        {publicRoutes.oauth.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>

      {/* Public authentication routes */}
      <Route element={<AuthLayout />}>
        {publicRoutes.auth.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>

      {/* Protected routes */}
      <Route element={<MainLayout />}>
        {authenticatedRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
    </Routes>
  );
}

export default AppRoutes;

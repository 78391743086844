import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreate } from "../../../services/endpointService";
import routes from "../../../configs/routes";
import BaseForm from "../Form/BaseForm";

import Heading from "../../../components/Heading/Heading";

const Create = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { collectionUuid } = useParams();

  const [formInitialData, setFormInitialData] = useState({
    method: "GET",
    code: 200,
    type: "json",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const importData = location.state?.initialData;

  useEffect(() => {
    if (importData) {
      setFormInitialData((prevData) => ({
        ...prevData,
        method: importData.method || prevData.method,
        code: importData.code || prevData.code,
        type: importData.type || prevData.type,
        headers: importData.headers || prevData.headers,
        path: importData.path,
        name: importData.name,
        description: importData.description,
        body: importData.body,
      }));
    }
  }, [importData]);

  const { data, mutate: create, error, isSuccess, isPending } = useCreate();

  const handleCreate = (formData) => {
    create({
      endpoint: formData,
      uuid: collectionUuid,
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      navigate(routes.auth.endpoint.list(collectionUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  return (
    <>
      <Heading
        back={t("Endpoints")}
        to={routes.auth.endpoint.list(collectionUuid)}
        title={t("Create new endpoint")}
      />
      <BaseForm
        initialData={formInitialData}
        onSubmit={handleCreate}
        error={error}
        success={isSuccess}
        isLoading={isPending}
        successMessage={t("Endpoint created successfully.")}
        submitButtonText={t("Create endpoint")}
        submitButtonIcon="ti-square-rounded-plus"
      />
    </>
  );
};

export default Create;

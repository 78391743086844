import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInviteByLink } from "../../../services/inviteService";
import Modal from "react-bootstrap/Modal";
import Button from "../../../components/Button/Button";
import Select from "../../../components/Forms/Select";

const InviteByLink = () => {
  const { t } = useTranslation();
  const { mutate: generateInvite, error, data, isPending } = useInviteByLink();
  const [formData, setFormData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleGenerateLink = () => {
    generateInvite(formData);
  };

  useEffect(() => {
    if (data) {
      setModalShow(true);
      setModalData({
        link: `${process.env.REACT_APP_BASE_URL}/invitation/${data?.token}`,
      });
    }
  }, [data]);

  const handleCopy = () => {
    navigator.clipboard.writeText(modalData.link);
    setCopySuccess(true);
  };

  return (
    <>
      <div className="">
        <div className="row d-flex justify-content-end">
          <div className="col-lg-3 pe-0">
            <Select
              name="role"
              label={t("Role")}
              onChange={(value) => setFormData({ role: value })}
              options={[
                {
                  value: "owner",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-crown fs-4 text-warning"></i>
                      <span className="ms-1">{t("Owner")}</span>
                    </div>
                  ),
                },
                {
                  value: "admin",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-user fs-4 text-primary"></i>
                      <span className="ms-1">{t("Admin")}</span>
                    </div>
                  ),
                },
                {
                  value: "member",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-users fs-4 text-primary"></i>
                      <span className="ms-1">{t("Member")}</span>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="col-auto mt-4">
            <Button
              type="button"
              isLoading={isPending}
              isDisabled={!formData.role}
              variant="secondary"
              onClick={handleGenerateLink}
            >
              <span>{isPending ? t("Generating...") : t("Generate link")}</span>
            </Button>
          </div>
        </div>
        {error && (
          <div className="d-flex justify-content-end text-danger" role="alert">
            {error}
          </div>
        )}
      </div>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <h5 className="mb-0">{t("Generate Invite Link")}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-2">
            <input
              type="readonly"
              value={modalData?.link}
              className="form-control"
            />
            <Button
              variant={copySuccess ? "success" : "secondary"}
              className="icon avatar"
              onClick={handleCopy}
            >
              {copySuccess ? (
                <i className="ti ti-checkbox fs-5"></i>
              ) : (
                <i className="ti ti-copy fs-5"></i>
              )}
            </Button>
          </div>
          <p className="ms-1 mt-3 mb-0">{t("Expires in 24 hours")}</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="white" onClick={() => setModalShow(false)}>
            <span>{t("Close")}</span>
          </Button>
          <Button
            type="button"
            variant="secondary"
            isLoading={isPending}
            disabled={isPending}
            onClick={handleGenerateLink}
          >
            <span>
              {isPending ? t("Generating...") : t("Generate new link")}
            </span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InviteByLink;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchMembers } from "../../services/organizationService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Alert from "../../components/Alert/Alert";
import OrganizationNav from "./OrganizationNav";
import { formatDate } from "../../utils/helpers";
import OptionsDropdown from "./Members/OptionsDropdown";
import AccountCard from "../../components/User/AccountCard";

const OrganizationMembers = () => {
  const { t } = useTranslation();
  const { session, account, isOrganizationRole } = useAccount();
  const {
    data: membersData,
    isPending: isFetchPending,
    error: isFetchError,
  } = useFetchMembers(session.members);
  const [members, setMembers] = useState({});

  useEffect(() => {
    if (membersData) {
      const { members } = membersData;
      setMembers(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersData]);

  return (
    <>
      <Heading
        to={routes.auth.organization.overview}
        back={t("Organization")}
        title={t("Organization members")}
      />
      {isFetchError && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      <OrganizationNav />
      <section className="pt-4">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-users fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Organization members")}</h3>
            </div>
            <p className="small">
              {t("Here are the members in your organization")}
            </p>
          </div>
          <div className="col-lg-8"></div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Member")}</th>
              <th scope="col">{t("Role")}</th>
              <th scope="col">{t("Date joined")}</th>
              <th scope="col text-end"></th>
            </tr>
          </thead>
          {!isFetchPending && members && members.length > 0 && (
            <tbody>
              {members.map((member, index) => (
                <tr key={`org-member-${index}_${member.uuid}`}>
                  <td className="align-middle">
                    <div className="d-flex align-items-center">
                      <AccountCard
                        firstName={member?.first_name}
                        lastName={member?.last_name}
                        avatar={member?.avatar}
                        detail={member?.email}
                      />
                      {member.uuid === account?.uuid && (
                        <div className="ms-2 d-flex">
                          <div className="badge bg-success bg-opacity-10 text-success ms-2">
                            {t("You")}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="align-middle text-capitalize">
                    {member.organization_users.role}
                  </td>
                  <td className="align-middle">
                    {formatDate(member.created_at)}
                  </td>
                  <td className="align-middle text-end">
                    {isOrganizationRole("owner") &&
                      account.uuid !== member.uuid && (
                        <div className="d-flex justify-content-end">
                          <OptionsDropdown uuid={member.uuid} />
                        </div>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </section>
    </>
  );
};

export default OrganizationMembers;

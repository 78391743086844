import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreateWorkspace } from "../../../services/workspaceService";
import BaseForm from "./BaseForm";
import routes from "../../../configs/routes";

const CreateForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mutate: createWorkspace,
    error: createError,
    isSuccess: createSuccess,
    isPending: createPending,
    data: collectionData,
  } = useCreateWorkspace();

  useEffect(() => {
    if (createSuccess && collectionData) {
      navigate(`${routes.auth.workspace.list}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData, createSuccess]);

  const handleCreate = (formData) => {
    createWorkspace(formData);
  };

  return (
    <BaseForm
      onSubmit={handleCreate}
      error={createError}
      success={createSuccess}
      isLoading={createPending}
      successMessage={t("Workspace created successfully.")}
      submitButtonText={t("Create workspace")}
      submitButtonIcon="ti-square-rounded-plus"
    />
  );
};

export default CreateForm;

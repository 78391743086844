import ButtonLink from "../../Button/ButtonLink";
import { useTranslation } from "react-i18next";
import routes from "../../../configs/routes";

const Empty = () => {
  const { t } = useTranslation();

  return (
    <div className="my-5 mx-auto text-center col-sm-12 col-md-9 col-xl-6 col-xxl-5">
      <h4 className="mb-3">📭 {t("No collections yet!")}</h4>
      <p>{t("It seems you don't have any collection yet.")}</p>
      <div className="mt-4">
        <ButtonLink
          href={routes.auth.collection.create}
          size="lg"
          variant="primary"
        >
          <i className="ti ti-plus fs-5"></i>
          <span>{t("Create a collection")}</span>
        </ButtonLink>
      </div>
    </div>
  );
};

export default Empty;

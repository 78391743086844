const authRoute = {
  home: `/`,
  collections: `/collections`,
  collection: {
    create: `/collection/create`,
    edit: (uuid) => `/collection/${uuid}/edit`,
    openApi: (uuid) => `/collection/${uuid}/openapi`,
  },
  endpoint: {
    list: (uuid) => `/collection/${uuid}/endpoints`,
    create: (collectionUuid) => `/collection/${collectionUuid}/endpoint/create`,
    edit: (uuid) => `/endpoint/${uuid}/edit`,
  },
  requests: (uuid) => `/collection/${uuid}/requests`,
  request: {
    view: (uuid, request) => `/collection/${uuid}/request/${request}`,
  },
  account: {
    overview: `/account`,
    security: `/account/security`,
  },
  organization: {
    overview: `/organization`,
    members: `/organization/members`,
    invite: `/organization/invite`,
  },
  workspace: {
    overview: `/workspace`,
    list: `/workspaces`,
    create: `/workspace/create`,
    settings: `/workspace/settings`,
    members: `/workspace/members`,
  },
};

const publicRoute = {
  login: "/auth/login",
  signup: "/auth/signup",
  github: "/auth/callback/github",
  invite: "/invitation/:token?",
};

const routes = {
  auth: authRoute,
  public: publicRoute,
};

export default routes;

import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Editor from "@monaco-editor/react";
import Heading from "../../components/Heading/Heading";
import routes from "../../configs/routes";
import CollectionNav from "../../components/Heading/CollectionNav";
import { useFetchOpenApi } from "../../services/collectionService";
import YamlPlaceholder from "../../components/Collections/Messages/YmlPlaceholder";
import { apiDomain } from "../../utils/helpers";

const OpenApi = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();

  const { data: collection, isPending } = useFetchOpenApi(uuid);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleEditorDidMount = () => {
    setIsEditorReady(true);
  };

  const handleEditorChange = (value, event) => {};

  const handleEditorValidation = (markers) => {
    if (markers?.length === 0) {
      setErrorMessage(null);
      return;
    }
    const { message } = markers[0];
    const line = markers[0].startLineNumber;

    setErrorMessage(`Line ${line}: ${message}`);
  };

  return (
    <>
      <Heading
        back={t("Endpoints")}
        to={routes.auth.endpoint.list(uuid)}
        title={t("Collection OpenAPI")}
      />
      <CollectionNav uuid={uuid} />

      <div className="row">
        <div className="col-lg-9">
          {(!isEditorReady || isPending) && <YamlPlaceholder />}
          {!isPending && collection?.schema && (
            <div
              role="textbox"
              className={`monaco-editor-container ${
                !isEditorReady ? "d-none" : "d-block"
              }`}
            >
              <Editor
                key="openapi-editor"
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                height="calc(100vh - 200px)"
                language="yaml"
                defaultValue={collection?.schema}
                className="form-control p-0"
                onValidate={handleEditorValidation}
                options={{
                  minimap: { enabled: false },
                  selectionHighlight: false,
                  scrollBeyondLastLine: false,
                  renderLineHighlight: "none",
                  tabIndex: 0,
                  fontSize: 13,
                  readOnly: false,
                  wordWrap: "on",
                  lineNumbers: "on",
                  renderIndentGuides: true,
                  formatOnPaste: true,
                  formatOnType: true,
                  ariaLabel: "OpenAPI schema",
                }}
              />
              {errorMessage && (
                <div className="form-text text-danger small">
                  {errorMessage}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <div className="card p-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-info-circle fs-4 text-primary"></i>
              <h3 className="fs-5 mb-0">Schema actions</h3>
            </div>
            <div className="mt-3">
              <Link
                to={`${apiDomain(collection?.domain)}/__docs`}
                target="_blank"
                className="btn btn-white w-100"
              >
                <div className="d-flex align-items-center gap-2">
                  <i className="ti ti-external-link fs-5"></i>
                  <span>{t("Open documentation UI")}</span>
                </div>
              </Link>
            </div>
            <div className="mt-3 download-schema">
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                  collection?.schema
                )}`}
                download={`${collection?.domain}.yaml`}
                className="btn btn-white w-100"
              >
                <div className="d-flex align-items-center gap-2">
                  <i className="ti ti-download fs-5"></i>
                  <span>{t("Download schema")}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenApi;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import routes from "../../configs/routes";
import Tooltip from "../Tooltip/Tooltip";
import Visibility from "./Visibility";

const WorkspaceSwitcher = () => {
  const { t } = useTranslation();
  const [switchModal, setSwitchModal] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const queryClient = useQueryClient();
  const { session, setSession, workspaces } = useAccount();

  useEffect(() => {
    if (session?.workspace) {
      const workspace = workspaces.find((w) => w.uuid === session.workspace);
      setSelectedWorkspace(workspace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, workspaces]);

  const handleModalSwitch = () => {
    setSwitchModal(true);
  };

  const handleSelectWorkspace = (uuid) => {
    const workspace = workspaces.find((w) => w.uuid === uuid);

    // Prevent closing the modal if the selected workspace is already selected
    if (selectedWorkspace?.uuid === workspace.uuid) {
      return;
    }

    setSession((prev) => ({
      ...prev,
      workspace: workspace.uuid,
    }));

    setSelectedWorkspace(workspace);

    queryClient.invalidateQueries({
      queryKey: ["collections", "widgets"],
    });

    setSwitchModal(false);

    // @TODO: Fix force a page reload (for now)
    window.location.reload();
  };

  return (
    <div className="w-100">
      <Dropdown>
        <Dropdown.Toggle
          variant="none"
          className="p-0 border-0 w-100"
          id="dropdown-worspace"
          drop="down"
        >
          <div className="text-start card p-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-block text-truncate">
                <small className="text-muted">{t("Workspace")}</small>
                <div className="h6 mb-0 text-truncate">
                  {selectedWorkspace?.name ? (
                    selectedWorkspace?.name
                  ) : (
                    <div
                      className="placeholder-wave"
                      style={{ width: "120px", height: "16.8px" }}
                    >
                      <div className="placeholder rounded w-100 h-100"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <i className="ti ti-chevron-down text-muted fs-4"></i>
              </div>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100" style={{ minWidth: "240px" }}>
          <div className="dropdown-item-text">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-block text-truncate">
                <div className="h6 mb-0 text-truncate">
                  {selectedWorkspace?.name}
                </div>
                <small className="text-muted">
                  <Visibility
                    visibility={selectedWorkspace?.visibility}
                    preview="sort"
                  />
                </small>
              </div>
              {workspaces.length > 1 && (
                <Tooltip message={t("Switch workplace")} placement="top">
                  <button
                    className="btn btn-icon p-0"
                    onClick={handleModalSwitch}
                  >
                    <i className="ti ti-switch-horizontal fs-4"></i>
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
          <Dropdown.Divider />
          <Link
            to={routes.auth.workspace.overview}
            className="dropdown-item d-flex align-items-center gap-1"
          >
            <div className="d-flex align-items-center">
              <i className="ti ti-settings-2 fs-5"></i>
            </div>
            <div>
              <div className="d-block">{t("Overview")}</div>
            </div>
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        className="border-0"
        show={switchModal}
        onHide={() => setSwitchModal(false)}
        animation={true}
        centered
      >
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <h5 className="modal-title">{t("Your workspaces")}</h5>
        </Modal.Header>
        <Modal.Body>
          {workspaces.length === 0 ? (
            <p>No workspaces found</p>
          ) : (
            workspaces?.map((workspace) => (
              <div
                className={`p-3 mb-2 ${
                  selectedWorkspace?.uuid === workspace.uuid
                    ? "cursor-default"
                    : "cursor-pointer card hover-flex"
                } `}
                role="button"
                key={workspace.uuid}
                onClick={() => handleSelectWorkspace(workspace.uuid)}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-block text-start">
                    <div className="h6 mb-0">{workspace.name}</div>
                    <small className="text-muted">
                      <Visibility
                        visibility={selectedWorkspace?.visibility}
                        preview="sort"
                      />
                    </small>
                  </div>
                  {selectedWorkspace?.uuid === workspace.uuid ? (
                    <div className="d-flex gap-1 align-items-center bg-success bg-opacity-10 rounded py-1 px-2">
                      <span className="text-success">{t("Currently on")}</span>
                    </div>
                  ) : (
                    <div className="child gap-1 align-items-center bg-light rounded py-1 px-2">
                      <i className="ti ti-switch-horizontal fs-5"></i>
                      <span>{t("Switch to")}</span>
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WorkspaceSwitcher;

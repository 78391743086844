import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "../../../components/Button/Button";
import { useUnassignMember } from "../../../services/organizationService";
import { useEffect } from "react";
import Select from "../../../components/Forms/Select";

const EditOption = ({ uuid }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [role, setRole] = useState("");

  const { mutate: changeRole, error, data, isPending } = useUnassignMember();

  const handleClearAll = async () => {
    changeRole(role, uuid);
  };

  useEffect(() => {
    if (!isPending && data?.status) {
      handleClose();
    }
  }, [data, isPending]);

  const handleRoleChange = (role) => {
    setRole(role);
  };

  return (
    <>
      <div
        className="dropdown-item option-edit"
        role="button"
        onClick={handleShow}
      >
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <i className="ti ti-user-cog fs-4"></i>
          </div>
          <div className="ms-3">
            <strong className="d-block fw-semibold">{t("Role")}</strong>
            <small className="d-block">{t("Change member role")}</small>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        ></Modal.Header>
        <Modal.Body className="text-center col-lg-10 m-auto mt-0 mb-3">
          <i className="ti ti-user fs-1 text-primary"></i>
          <h5 className="mt-2 mb-3">{t("Edit member role")}</h5>
          <div className="d-flex justify-content-center">
            <Select
              name="role"
              label={t("New role")}
              onChange={handleRoleChange}
              options={[
                {
                  value: "owner",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-crown fs-4 text-warning"></i>
                      <span className="ms-1">{t("Owner")}</span>
                    </div>
                  ),
                },
                {
                  value: "admin",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-user fs-4 text-primary"></i>
                      <span className="ms-1">{t("Admin")}</span>
                    </div>
                  ),
                },
                {
                  value: "member",
                  label: (
                    <div className="d-flex align-items-center">
                      <i className="ti ti-users fs-4 text-primary"></i>
                      <span className="ms-1">{t("Member")}</span>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          {role && (
            <>
              {role === "owner" && (
                <div className="mb-3 alert bg-danger bg-opacity-25 text-danger d-flex align-items-center text-start">
                  <i className="ti ti-alert-circle fs-3 me-2"></i>
                  <span>
                    {t(
                      "The user will have full access to the organization, workspaces, collections, and other resources."
                    )}
                  </span>
                </div>
              )}
              {role === "admin" && (
                <div className="mb-3 alert bg-warning bg-opacity-25 text-warning d-flex align-items-center text-start">
                  <i className="ti ti-alert-triangle fs-3 me-2"></i>
                  <span>
                    {t(
                      "The user will have access to invite new members, manage workspaces, collections, and other resources."
                    )}
                  </span>
                </div>
              )}
              <p className="mb-0">
                <span>{t("The new role will be applied to the member")}:</span>
                <span
                  className={`ms-1 fw-semibold ${
                    role === "owner" ? "text-danger" : "text-primary"
                  }`}
                >
                  {role}
                </span>
              </p>
              <p className="mb-0">{t("Are you sure you want to change it?")}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          {error && (
            <div className="small text-danger" role="alert">
              {t("An error occurred while removing the member.")}
            </div>
          )}
          <div className="d-flex gap-2 justify-content-end">
            <Button variant="white" onClick={handleClose}>
              <span>{t("No, cancel")}</span>
            </Button>
            <Button
              variant="primary"
              isLoading={isPending}
              isDisabled={isPending}
              onClick={handleClearAll}
            >
              <span>{t("Yes, change it")}</span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditOption;

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";
import { useFetchUser } from "./userService";

export const useFetchOrganization = () => {
  const { data: userData } = useFetchUser();
  return useQuery({
    queryKey: ["organization"],
    queryFn: () => fetchWithErrorHandling(`/organization/details`),
    enabled: !!userData,
  });
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organization }) =>
      fetchWithErrorHandling(`/organization/update`, {
        method: "PUT",
        body: JSON.stringify(organization),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};

export const useFetchMembers = () => {
  const { data: userData } = useFetchUser();
  return useQuery({
    queryKey: ["members"],
    queryFn: () => fetchWithErrorHandling(`/organization/members`),
    enabled: !!userData,
  });
};

export const useUnassignMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid }) =>
      fetchWithErrorHandling(`/organization/member/${uuid}/unassign`, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../configs/routes";

const WorkspaceNav = ({ isOwner }) => {
  const { t } = useTranslation();

  return (
    <ul className="nav nav-underline border-bottom mb-4">
      <li className="nav-item">
        <Link
          to={routes.auth.workspace.overview}
          className={`nav-link px-2 fw-normal ${
            window.location.pathname === routes.auth.workspace.overview
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Overview")}
        </Link>
      </li>
      {isOwner && (
        <li className="nav-item">
          <Link
            to={routes.auth.workspace.members}
            className={`nav-link px-2 fw-normal ${
              window.location.pathname === routes.auth.workspace.members
                ? "active cursor-default"
                : "cursor-pointer"
            }`}
          >
            {t("Members")}
          </Link>
        </li>
      )}
      <li className="nav-item">
        <Link
          to={routes.auth.workspace.list}
          className={`nav-link px-2 fw-normal ${
            window.location.pathname === routes.auth.workspace.list
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Your workspaces")}
        </Link>
      </li>
      {isOwner && (
        <li className="nav-item">
          <Link
            to={routes.auth.workspace.settings}
            className={`nav-link px-2 fw-normal ${
              window.location.pathname === routes.auth.workspace.settings
                ? "active cursor-default"
                : "cursor-pointer"
            }`}
          >
            {t("Settings")}
          </Link>
        </li>
      )}
    </ul>
  );
};

export default WorkspaceNav;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAiSuggestion } from "../../../services/endpointService";
import { slugifyPath } from "../../../utils/helpers";
import { bodyTypes, methods } from "../../../utils/mimeTypes";
import Modal from "react-bootstrap/Modal";
import InputHeaders from "./InputHeaders";
import StatusCodeSuggester from "./StatusCodeSuggester";
import Input from "../../../components/Forms/Input";
import TextEditor from "../../../components/Forms/TextEditor";
import Select from "../../../components/Forms/Select";
import Textarea from "../../../components/Forms/Textarea";
import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Alert from "../../../components/Alert/Alert";
import "./Form.scss";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Your collection needs a name.").min(3).max(64),
  description: Yup.string().max(255).nullable(),
  path: Yup.string().required("Please provide a path").min(1).max(255),
  code: Yup.number().required("Please provide a status code").min(100).max(599),
  delay: Yup.number().min(0).max(180000),
});

const BaseForm = ({
  initialData,
  onSubmit,
  isLoading,
  error,
  success,
  successMessage,
  submitButtonText,
  submitButtonIcon,
  uuid = null,
}) => {
  const { t } = useTranslation();

  // Form state
  const [showSuggestOverlay, setShowSuggestOverlay] = useState(true);
  const [showDescription, setShowDescription] = useState(false);
  const [showDelayInput, setShowDelayInput] = useState(false);
  const [textEditorValue, setTextEditorValue] = useState("");
  const [show, setShow] = useState(false);

  // Initialize form
  const {
    register,
    setValue,
    watch,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  // AI suggestion hook
  const {
    data: aiSuggestion,
    mutate: getAiSuggestion,
    isPending,
  } = useAiSuggestion();

  const endpointName = watch("name");

  // Effect for initializing form data
  useEffect(() => {
    if (!initialData) return;

    Object.entries(initialData).forEach(([key, value]) => {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });

    handleInitialFormState(initialData);
  }, [initialData, setValue]);

  useEffect(() => {
    if (!aiSuggestion) return;
    const { content } = aiSuggestion;

    setValue("body", content);
    setTextEditorValue(content);
    setShowSuggestOverlay(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiSuggestion]);

  // Helper functions
  const handleInitialFormState = (data) => {
    if (data?.name && !data?.body) {
      setShowSuggestOverlay(true);
    }
    if (data?.description) {
      setShowDescription(true);
    }
    if (data?.body) {
      setTextEditorValue(data.body);
      setShowSuggestOverlay(false);
    }
    if (data?.delay !== undefined && ![0, 100, 300].includes(data.delay)) {
      setShowDelayInput(true);
    }
  };

  const handlePathChange = (value) => {
    setValue("path", slugifyPath(value));
    if (value.length > 0) {
      clearErrors("path");
    }
  };

  const handleAiSuggestions = () => {
    setShow(false);
    getAiSuggestion(getValues());
  };

  const handleToggleDescription = (action) => {
    setShowDescription(action === "show");
    if (action === "hide") {
      setValue("description", "");
    }
  };

  const getNetworkDelayDefaultValue = (delay) => {
    if (delay === undefined || delay === null) return 0;
    return [0, 100, 300].includes(delay) ? delay : "custom";
  };

  const handleNetworkDelay = (value) => {
    const isCustom = value === "custom";
    setShowDelayInput(isCustom);
    setValue("delay", isCustom ? 0 : Number(value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert variant="danger" title={t("Failed")}>
          {t("Something went wrong! Please try again")}
        </Alert>
      )}
      {success && (
        <Alert variant="success" title={t("Updated")}>
          {successMessage}
        </Alert>
      )}
      <section className="border-top pt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-file-description fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Endpoint overview")}</h3>
            </div>
            <p className="small">
              {t("Provide a name and description for your endpoint")}
            </p>
          </div>
          <div className="col-lg-8">
            <Input
              name="name"
              label={t("Name")}
              placeholder={t("Request name")}
              register={register}
              errors={errors}
              autoComplete="off"
              maxLength="64"
              required
            />

            {!showDescription && !initialData?.description ? (
              <div className="d-flex align-items-center gap-2">
                <button
                  type="button"
                  className="px-2 py-1 d-flex align-items-center gap-1 btn btn-transparent"
                  onClick={() => handleToggleDescription("show")}
                >
                  <i className="ti ti-plus fs-6 text-primary"></i>
                  <span>{t("Add description")}</span>
                </button>
                <div className="d-flex gap-1 text-muted align-items-center">
                  <i className="ti ti-bulb fs-5"></i>
                  <strong className="fw-semibold small">{t("Tip")}:</strong>
                  <span className="small">
                    {t(
                      "A description helps the AI generate better suggestions"
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <div className="description__content">
                <Textarea
                  name="description"
                  label={t("Description")}
                  register={register}
                  errors={errors}
                  rows={2}
                />
                <div className="d-flex justify-content-end">
                  <div>
                    <button
                      type="button"
                      className="px-2 py-1 d-flex align-items-center gap-1 btn btn-transparent btn-sm"
                      onClick={() => handleToggleDescription("hide")}
                    >
                      <i className="ti ti-eraser fs-5 text-danger"></i>
                      <span>{t("Clear and hide")}</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section id="request" className="border-top pt-4 mt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-world fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Request")}</h3>
            </div>
            <p className="small">
              {t("Configure the request method and path for your endpoint")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-3 col-lg-3">
                <Select
                  label={t("Method")}
                  name="method"
                  register={register}
                  errors={errors}
                  options={methods}
                  className="w-100 d-flex justify-content-center"
                  defaultValue={initialData?.method || "GET"}
                  onChange={(value) => setValue("method", value)}
                />
              </div>
              <div className="col-lg-9">
                <Input
                  name="path"
                  label={t("Path")}
                  autoComplete="off"
                  maxLength="255"
                  register={register}
                  errors={errors}
                  placeholder={"/api/v1/users"}
                  onChange={(e) => handlePathChange(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="border-top pt-4 mt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-server-2 fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Response")}</h3>
            </div>
            <p className="small">
              {t("Configure the response status code and headers")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <Input
                  type="number"
                  name="code"
                  label={t("Status code")}
                  register={register}
                  errors={errors}
                  min="100"
                  max="599"
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-9 mt-4 pt-2">
                <StatusCodeSuggester
                  onSelectStatusCode={(code) => setValue("code", code)}
                  title={initialData?.name || getValues()?.name}
                  description={
                    initialData?.description || getValues()?.description
                  }
                />
              </div>
            </div>
            <InputHeaders
              defaultHeaders={initialData?.headers}
              onChange={(value) => setValue("headers", value)}
            />
            <div className="mt-3 row">
              <div className="col-sm-12 col-md-8 col-lg-4">
                <Select
                  name="metwork-delay"
                  register={register}
                  errors={errors}
                  label={t("Simulate a fixed network delay")}
                  className="w-100 d-flex justify-content-center"
                  defaultValue={getNetworkDelayDefaultValue(initialData?.delay)}
                  options={[
                    { value: 0, label: t("No delay selected") },
                    { value: 300, label: t("2G (~300ms)") },
                    { value: 100, label: t("3G (~100ms)") },
                    { value: "custom", label: t("Custom delay") },
                  ]}
                  onChange={(value) => handleNetworkDelay(value)}
                />
              </div>
              <div
                className={`col-sm-12 col-md-8 col-lg-3 ${
                  showDelayInput ? "d-block" : "d-none"
                }`}
              >
                <Input
                  type="number"
                  name="delay"
                  register={register}
                  errors={errors}
                  defaultValue={initialData?.delay ?? 0}
                  placeholder="0 - 180000"
                  min="0"
                  max="180000"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="border-top pt-4 mt-4">
        <div className="row">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-code fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Response body")}</h3>
            </div>
            <p className="small">
              {t("Configure the response body for your endpoint")}
            </p>
          </div>
          <div className="col-lg-8">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <Select
                name="type"
                label={t("Body type")}
                register={register}
                errors={errors}
                defaultValue={initialData?.type}
                onChange={(value) => setValue("type", value)}
                className="w-100 d-flex justify-content-center"
                options={bodyTypes}
              />
            </div>
            <div className="position-relative body-wrapper-overlay">
              <div className="ai__buttons d-flex justify-content-end">
                <div className="sub-positioned d-flex">
                  {(aiSuggestion || isPending || initialData?.body) && (
                    <Tooltip message={t("Regenerate")}>
                      <div>
                        <Button
                          type="button"
                          variant="transparent icon btn-icon"
                          onClick={handleAiSuggestions}
                          isDisabled={isPending}
                        >
                          <i
                            className={`ti ti-refresh fs-4 text-gradient ${
                              isPending ? "refresh-icon" : "cta"
                            }`}
                          />
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {(endpointName?.length > 0 || initialData?.body) && (
                    <Tooltip message={t("Improve context")}>
                      <div>
                        <Button
                          type="button"
                          variant="transparent icon btn-icon"
                          onClick={() => setShow(true)}
                          isDisabled={isPending}
                        >
                          <i className="ti ti-sparkles fs-4 text-gradient" />
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              {showSuggestOverlay && (
                <div className="position-absolute body-overlay bg-light rounded w-100">
                  <div className="d-flex justify-content-center align-items-center p-4 h-100">
                    <div className="text-center">
                      <i className="ti ti-wand fs-1 text-gradient" />
                      <p className="mt-2">
                        {t(
                          "Let AI help you generate a response based on your endpoint configuration"
                        )}
                      </p>
                      {endpointName?.length === 0 ? (
                        <Tooltip message={t("Please provide a name first")}>
                          <div>
                            <Button
                              type="button"
                              variant="secondary"
                              isDisabled={true}
                            >
                              {t("Generate response")}
                            </Button>
                          </div>
                        </Tooltip>
                      ) : (
                        <Button
                          type="button"
                          variant="secondary"
                          isDisabled={isPending || !endpointName}
                          onClick={handleAiSuggestions}
                        >
                          {isPending
                            ? t("Generating...")
                            : t("Generate response")}
                        </Button>
                      )}

                      {!isPending && (
                        <div className="mt-2">
                          <div
                            className="link-primary small d-inline-block"
                            role="button"
                            onClick={() => setShowSuggestOverlay(false)}
                          >
                            {t("No, I'll write it myself")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <TextEditor
                id="body"
                label={t("HTTP response body")}
                defaultLanguage={initialData?.type ?? "json"}
                defaultValue={textEditorValue}
                onChange={(value) => setValue("body", value)}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="border-top pt-4 mt-4 d-flex justify-content-end">
        <Button
          variant="primary"
          isLoading={isLoading}
          isDisabled={!isValid || (errors && Object.keys(errors).length > 0)}
        >
          <i className={`ti ${submitButtonIcon} fs-4`}></i>
          <span>{submitButtonText}</span>
        </Button>
      </section>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <div>
            <h5 className="mb-0">{t("Enhance with AI")}</h5>
            <p className="mb-0">
              <small>
                {t(
                  "Provide additional context to generate a more accurate response"
                )}
              </small>
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Textarea
            name="context"
            register={register}
            errors={errors}
            label={t("Additional details")}
            placeholder={t("Add pagination, append an attribute...")}
            rows={2}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="white" onClick={() => setShow(false)}>
            <span>{t("Cancel")}</span>
          </Button>
          <Button variant="primary" onClick={handleAiSuggestions}>
            <i className="ti ti-wand fs-5"></i>
            <span>{t("Generate")}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default BaseForm;

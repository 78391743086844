import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGenerateCrud } from "../../../../services/aiService";
import routes from "../../../../configs/routes";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Forms/Input";
import Textarea from "../../../../components/Forms/Textarea";
import { isFeatureEnabled } from "../../../../utils/featureFlags";

const GenerateCrud = ({ collection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const { mutate: generateCrud, data, isPending, error } = useGenerateCrud();

  // FEATURE FLAG: Remove this line when the feature is ready
  const isCrudEnabled = isFeatureEnabled("aiCrudGeneration");

  const generateContent = () => {
    generateCrud({ ...formData, collection: collection.uuid });
  };

  useEffect(() => {
    if (data && collection.uuid) {
      setShowModal(false);
      console.log("Generated CRUD -> About to navigate", data);
      // Reload page to show the new collection
      navigate(routes.auth.endpoint.list(collection.uuid), {
        state: { refresh: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, collection]);

  if (!isCrudEnabled) {
    return (
      <div className="card p-5 position-relative text-muted w-100">
        <span
          className="position-absolute top-0 start-0 badge rounded-pill bg-danger"
          style={{ margin: "-10px" }}
        >
          {t("Comming soon")}
        </span>
        <i className="ti bg-primary bg-opacity-10 icon avatar m-auto p-2 ti-wand fs-3 text-muted"></i>
        <h5 className="mt-3 text-muted">{t("CRUD with AI")}</h5>
        <p>{t("Generate a complete CRUD using AI")}</p>
      </div>
    );
  }

  return (
    <>
      <div
        className="card p-5 position-relative w-100"
        role="button"
        onClick={() => setShowModal(true)}
      >
        <i className="ti bg-primary bg-opacity-10 icon avatar m-auto p-2 ti-wand fs-3 text-primary"></i>
        <h5 className="mt-3">{t("CRUD with AI")}</h5>
        <p>{t("Generate a complete CRUD using AI")}</p>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        >
          <div>
            <h5 className="mb-0">{t("Generate CRUD with AI")}</h5>
            <small className="text-muted">
              {t(
                "Make sure your collection details provide enough context for the AI."
              )}
            </small>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Input
            name="name"
            label={t("Collection name")}
            defaultValue={collection?.name}
            autoComplete="off"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <Textarea
            name="description"
            label={t("Collection description")}
            defaultValue={collection?.description}
            autoComplete="off"
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />
          {error && (
            <div className="text-danger mt-3" role="alert">
              {error}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="white" onClick={() => setShowModal(false)}>
            <span>{t("Cancel")}</span>
          </Button>
          <Button
            variant="primary"
            onClick={generateContent}
            isDisabled={isPending || !formData.name}
          >
            {isPending ? (
              <>
                <i className="ti ti-sparkles fs-5"></i>
                <span>{t("Generating...")}</span>
              </>
            ) : (
              <>
                <i className="ti ti-wand fs-5"></i>
                <span>{t("Generate")}</span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GenerateCrud;

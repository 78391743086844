import { useState, useEffect, useReducer, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Form, Offcanvas, FormControl } from "react-bootstrap";
import { formReducer } from "../../../reducers/endpoint/formReducer";
import { apiDomain } from "../../../utils/helpers";
import { methods } from "../../../utils/mimeTypes";
import StatusBadge from "./StatusBadge";
import Select from "../../../components/Forms/Select";
import ParamToInput from "../../../components/Forms/ParamToInput";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Button from "../../../components/Button/Button";
import InputText from "../../../components/Forms/InputText";
import "./Test.scss";

const Test = ({ endpoint, button }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState({});
  const [fullUrl, setFullUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, dispatch] = useReducer(formReducer, {
    path: "/",
    method: "",
    body: "",
    requestBody: "",
  });

  const formatHeaders = (headers) => {
    if (!headers) return "";

    return Array.from(headers.entries());
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!endpoint) return;

    setFullUrl(endpoint.path);

    dispatch({
      type: "SET_FORM_DATA",
      payload: endpoint,
    });
  }, [endpoint]);

  const handleFullUrlUpdate = useCallback((newUrl) => {
    setFullUrl(newUrl);
  }, []);

  const handleSendRequest = async () => {
    try {
      setLoading(true);
      const options = {
        method: formData.method,
        headers: {
          Accept: "*/*", // Accept any content type
        },
      };

      // Append endpoint headers to request headers
      if (endpoint.headers) {
        options.headers = { ...options.headers, ...endpoint.headers };
      }

      // Changed this part to use formData.body instead of requestBody
      if (formData.method === "POST" || formData.method === "PUT") {
        try {
          // First try to parse the body as JSON if it's a string
          const bodyData =
            typeof formData.body === "string"
              ? JSON.parse(formData.body)
              : formData.body;
          options.body = JSON.stringify(bodyData);
        } catch (e) {
          // If parsing fails, use the raw body
          options.body = formData.body;
        }
      }

      const response = await fetch(
        apiDomain(endpoint.domain, fullUrl),
        options
      );

      // Get the content type from response headers
      const contentType = response.headers.get("content-type");
      let data;

      // Handle empty response
      if (response.status === 204 || !contentType) {
        data = null;
      }
      // Handle different content types
      else if (contentType.includes("application/json")) {
        const text = await response.text(); // Get response as text first
        data = text ? JSON.parse(text) : null; // Parse only if there's content
      } else if (
        contentType.includes("application/xml") ||
        contentType.includes("text/xml")
      ) {
        const text = await response.text();
        // You might want to use a XML parser library here
        data = text;
      } else if (contentType.includes("text/plain")) {
        data = await response.text();
      } else {
        // For other types (e.g., binary data, PDFs, etc.)
        data = await response.blob();
      }

      setResponse({
        data: data,
        status: response.status || "unknown",
        headers: response.headers,
        content: response,
        type: contentType,
      });
      setLoading(false);
    } catch (error) {
      setResponse({
        data: error.message,
        status: error.status || "unknown",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleInputChange = useCallback((key, value) => {
    dispatch({ type: "SET_FORM_DATA", payload: { [key]: value } });
  }, []);

  const handleCopyCurl = () => {
    const curlCommand = `curl -X ${formData.method} "${apiDomain(
      endpoint.domain,
      fullUrl
    )}" ${formData?.body ? `-d '${formData.body}'` : ""}`;

    navigator.clipboard.writeText(curlCommand).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      },
      (err) => setCopied(false)
    );
  };

  return (
    <>
      <Tooltip message={t("Test it out")} placement="top">
        <div>
          <Button
            variant="white"
            size="sm"
            onClick={handleShow}
          >
            <i className="ti ti-test-pipe fs-5"></i>
            {button && <span className="ms-2">{button}</span>}
          </Button>
        </div>
      </Tooltip>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="offcanvas-test"
      >
        <Offcanvas.Header closeButton>
          <div className="d-flex align-items-center h6gap-2">
            <div className="d-flex align-items-center gap-1 bg-light rounded-1 p-1 text-truncate">
              <div
                className={`request rounded-1 px-2 py-0 method__${formData?.method}`}
              >
                {formData?.method}
              </div>
              <span className="font-monospace text-truncate">{fullUrl}</span>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div className="card p-3 mb-3">
            <div className="row">
              <div className="col-lg-3">
                <Select
                  id="method"
                  label={t("Method")}
                  defaultValue={formData?.method}
                  options={methods}
                  onChange={(value) => handleInputChange("method", value)}
                  className="w-100 d-flex justify-content-center"
                />
              </div>
              <div className="col-lg-8">
                <InputText
                  id="path"
                  label={t("Path")}
                  defaultValue={formData?.path}
                  onChange={(value) => handleInputChange("path", value)}
                  placeholder={"/api/v1/users"}
                />
              </div>
            </div>

            <ParamToInput path={formData.path} finalUrl={handleFullUrlUpdate} />

            {(formData.method === "POST" || formData.method === "PUT") && (
              <Form.Group className="mb-3">
                <Form.Label>Request body</Form.Label>
                <FormControl
                  as="textarea"
                  rows={5}
                  onChange={(value) => handleInputChange("requestBody", value)}
                  placeholder='{"key": "value"}'
                />
              </Form.Group>
            )}
          </div>
          {response.status && (
            <div className="card p-3 mb-5">
              <h5 className="mb-0">Response</h5>
              <div className="mt-3">
                <StatusBadge status={response.status} />
              </div>
              {response.headers && (
                <div className="mt-3">
                  <h6>Headers</h6>
                  <div className="mt-2 px-3 py-2 bg-light rounded">
                    {formatHeaders(response.headers).map(([key, value]) => (
                      <div key={key} className="mb-1">
                        <span className="fw-semibold">{key}</span>
                        <span className="mx-1">:</span>
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="mt-3">
                <h6>Response body</h6>
                <pre className="p-3 bg-light rounded">
                  {JSON.stringify(response.data, null, 2)}
                </pre>
              </div>
            </div>
          )}
        </Offcanvas.Body>
        <div className="position-absolute bottom-0 start-0 w-100 p-3 bg-white">
          <div className="d-flex justify-content-between align-items-center">
            <Button
              variant="primary"
              onClick={handleSendRequest}
              disabled={loading}
              isLoading={loading}
            >
              <i className="ti ti-send fs-5"></i>
              <span>Send request</span>
            </Button>
            <Button
              variant="secondary"
              className="d-flex align-items-center gap-1"
              onClick={handleCopyCurl}
              disabled={copied}
            >
              {copied ? (
                <>
                  <i className="ti ti-check fs-5"></i>
                  <span>Copied</span>
                </>
              ) : (
                <>
                  <i className="ti ti-code fs-5"></i>
                  <span>Copy cURL</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default Test;

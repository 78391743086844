import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchList = (uuid) =>
  useQuery({
    queryKey: ["endpoints"],
    queryFn: () => fetchWithErrorHandling(`/collection/${uuid}/endpoints`),
    enabled: !!uuid,
  });

export const useFetchWidgetEndpoints = () =>
  useQuery({
    queryKey: ["endpoints-widget"],
    queryFn: () => fetchWithErrorHandling(`/endpoint/widget/list`),
  });

export const useFetchItem = (uuid) =>
  useQuery({
    queryKey: ["endpoint", uuid],
    queryFn: () => fetchWithErrorHandling(`/endpoint/${uuid}`),
    enabled: !!uuid,
  });

export const useCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uuid, endpoint }) =>
      fetchWithErrorHandling(`/endpoint/create`, {
        method: "POST",
        body: JSON.stringify({ ...endpoint, uuid: uuid }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["endpoints"] });
    },
  });
};

export const useUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uuid, endpoint }) =>
      fetchWithErrorHandling(`/endpoint/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(endpoint),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["workspendpointace", variables.uuid],
      });

      queryClient.invalidateQueries({
        queryKey: ["endpoints"],
      });
    },
  });
};

export const useDuplicate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uuid, endpoint }) =>
      fetchWithErrorHandling(`/endpoint/${uuid}/duplicate`, {
        method: "POST",
        body: JSON.stringify(endpoint),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["endpoints"] });
    },
  });
};

export const useDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/endpoint/${uuid}`, { method: "DELETE" }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["endpoints"] });
    },
  });
};

export const useAiSuggestion = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/endpoint/ai-suggestion`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

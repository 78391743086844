import { GitHubCallback, Login, Signup } from "../pages/Auth";
import Invitation from "../pages/Auth/Invitation";
import routes from "../configs/routes";

export const publicRoutes = {
  oauth: [
    {
      path: routes.public.github,
      Component: GitHubCallback,
    },
  ],
  auth: [
    {
      path: routes.public.login,
      Component: Login,
    },
    {
      path: routes.public.signup,
      Component: Signup,
    },
    {
      path: routes.public.invite,
      Component: Invitation,
    },
  ],
};

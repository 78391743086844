import { CollectionRoutes } from "./modules/collections";
import { EndpointRoutes } from "./modules/endpoints";
import { RequestRoutes } from "./modules/requests";
import { AccountRoutes } from "./modules/account";
import { OrganizationRoutes } from "./modules/organization";
import { WorkspaceRoutes } from "./modules/workspace";
import { HomeRoutes } from "./modules/home";

export const authenticatedRoutes = [
  ...HomeRoutes,
  ...CollectionRoutes,
  ...EndpointRoutes,
  ...RequestRoutes,
  ...AccountRoutes,
  ...OrganizationRoutes,
  ...WorkspaceRoutes,
];

import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Heading.scss";

const Heading = ({ back, to, title, subtitle, actionButtons }) => {
  useEffect(() => {
    document.title = title || "castAPI";
  }, [title]);

  return (
    <div className="heading row d-flex justify-content-between align-items-center mb-3">
      <div className="col-sm-12 col-md-12 col-lg-5 col-xxl-7 d-flex justify-content-center justify-content-lg-start">
        <div className="text-truncate">
          {to && (
            <div className="d-flex justify-content-center justify-content-lg-start">
              <Link
                to={to}
                className="text-muted d-flex align-items-center text-decoration-none"
              >
                <i className="ti ti-square-rounded-arrow-left-filled"></i>
                <small className="ms-1">{back}</small>
              </Link>
            </div>
          )}
          <div className="mt-1">
            <h2 className="fs-4 mb-0 text-truncate">
              {title ? (
                title
              ) : (
                <div className="placeholder-glow" style={{ width: "400px" }}>
                  <span className="placeholder rounded col-8"></span>
                </div>
              )}
            </h2>
            {subtitle && <small className="text-muted">{subtitle}</small>}
          </div>
        </div>
      </div>
      {actionButtons && (
        <div className="col-auto mx-auto me-lg-0 mt-3 mt-lg-0">
          {actionButtons}
        </div>
      )}
    </div>
  );
};

export default Heading;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetchItem } from "../../services/workspaceService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Alert from "../../components/Alert/Alert";
import WorkspaceNav from "./WorkspaceNav";
import { formatDate } from "../../utils/helpers";
import AccountCard from "../../components/User/AccountCard";

const WorkspaceMembers = () => {
  const { t } = useTranslation();
  const { session, account, isWorkspaceOwner } = useAccount();
  const {
    data: workspace,
    isPending: isFetchPending,
    isFetchError,
  } = useFetchItem(session.workspace);
  const [members, setMembers] = useState({});

  useEffect(() => {
    setMembers([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Heading
        to={routes.auth.workspace.overview}
        back={t("Workspace")}
        title={t("Workspace users")}
      />
      {isFetchError && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      <WorkspaceNav
        isOwner={isWorkspaceOwner(workspace?.owner_uuid) ?? false}
      />
      <section className="pt-4">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-1 mb-2">
              <i className="ti ti-users fs-4 text-primary" />
              <h3 className="fs-5 mb-0">{t("Workspace users")}</h3>
            </div>
            <p className="small">
              {t("List of users in this workspace and their roles")}
            </p>
          </div>
          <div className="col-lg-8"></div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Member")}</th>
              <th scope="col">{t("Role")}</th>
              <th scope="col">{t("Date joined")}</th>
              <th scope="col text-end"></th>
            </tr>
          </thead>
          {members && members.length > 0 && (
            <tbody>
              {members.map((member, index) => (
                <tr key={`org-member-${index}_${member.uuid}`}>
                  <td className="align-middle">
                    <div className="d-flex align-items-center">
                      <AccountCard
                        firstName={member?.first_name}
                        lastName={member?.last_name}
                        avatar={member?.avatar}
                        detail={member?.email}
                      />
                      {member.uuid === account?.uuid && (
                        <div className="ms-2 d-flex">
                          <div className="badge bg-success bg-opacity-10 text-success ms-2">
                            {t("You")}
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="align-middle text-capitalize">
                    {member.organization_users.role}
                  </td>
                  <td className="align-middle">
                    {formatDate(member.created_at)}
                  </td>
                  <td className="align-middle text-end">
                    {member.uuid === account?.uuid && (
                      <div className="d-flex justify-content-end"></div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </section>
    </>
  );
};

export default WorkspaceMembers;

export const featureFlags = {
  aiCrudGeneration: {
    enabled: true,
    environments: ["development", "production"],
  },
  teamCollaboration: {
    enabled: true,
    environments: ["development", "production"],
  },
  invoicing: {
    enabled: false,
    environments: ["production"],
    startDate: "2025-01-01",
    endDate: "2025-12-31",
  },
};

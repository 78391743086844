import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../configs/routes";

const CollectionNav = ({ uuid }) => {
  const { t } = useTranslation();
  const currentPaths = window.location.pathname.split("/");

  return (
    <ul className="nav nav-underline border-bottom mb-4">
      <li className="nav-item">
        <Link
          to={routes.auth.endpoint.list(uuid)}
          className={`nav-link px-3 fw-normal ${
            currentPaths.includes("endpoints")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Endpoints")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.requests(uuid)}
          className={`nav-link px-3 fw-normal ${
            currentPaths.includes("requests") ||
            currentPaths.includes("request")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Requests")}
        </Link>
      </li>
      <li className="nav-item d-none">
        <Link
          to={routes.auth.endpoint.list(uuid)}
          className="nav-link disabled position-relative"
        >
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            Soon
          </span>
          {t("Chaos")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.collection.openApi(uuid)}
          className={`nav-link px-3 fw-normal ${
            currentPaths.includes("openapi")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("OpenAPI")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.collection.edit(uuid)}
          className={`nav-link px-3 fw-normal ${
            currentPaths.includes("edit")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Settings")}
        </Link>
      </li>
    </ul>
  );
};

export default CollectionNav;

import { RequestLits } from "../../pages/Requests";
import routes from "../../configs/routes";

export const RequestRoutes = [
  {
    path: routes.auth.requests(":uuid"),
    Component: RequestLits,
  },
  {
    path: routes.auth.request.view(":uuid", ":requestUuid"),
    Component: RequestLits,
  },
];

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchList = (shouldWait = false) =>
  useQuery({
    queryKey: ["collections"],
    queryFn: () => fetchWithErrorHandling(`/collection/list`),
    enabled: !shouldWait,
  });

export const useFetchItem = (uuid) =>
  useQuery({
    queryKey: ["collection", uuid],
    queryFn: () => fetchWithErrorHandling(`/collection/${uuid}`),
    enabled: !!uuid,
  });

export const useCreate = () =>
  useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/collection/create`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });

export const useUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ collection, uuid }) =>
      fetchWithErrorHandling(`/collection/${uuid}`, {
        method: "PUT",
        body: JSON.stringify(collection),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["collection", variables.uuid],
      });
    },
  });
};

export const useDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/collection/${uuid}/delete`, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["collections"] });
    },
  });
};

export const useCheckDomain = () =>
  useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/collection/domain`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });

export const useFetchOpenApi = (uuid) =>
  useQuery({
    queryKey: ["openapi", uuid],
    queryFn: () => fetchWithErrorHandling(`/collection/openapi/${uuid}`),
    enabled: !!uuid,
  });

export const useFetchWidgetCollections = (limit = 3) =>
  useQuery({
    queryKey: ["collections-limited", limit],
    queryFn: () => fetchWithErrorHandling(`/collection/list?limit=${limit}`),
  });

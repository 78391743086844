import { useMutation } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useGenerateEndpoint = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/ai/generate-endpoint`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

export const useGenerateCrud = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/ai/generate-crud`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

export const useSuggestResposeBody = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/ai/suggest-response-body`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchList = (uuid) =>
  useQuery({
    queryKey: ["requests", uuid],
    queryFn: () => fetchWithErrorHandling(`/request/collection/${uuid}/list`),
    refetchInterval: 10000,
  });

export const useDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/request/collection/${uuid}/delete`, {
        method: "DELETE",
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["requests", variables.uuid],
      });
    },
  });
};
